export class uiwConstants {
    static uiwApiEndPoints = {
        getProjectList: `/api/v1/uiw/projects.json?page={pageNumber}&q={searchText}&per_page={perPage}`,
        getComments: '/api/v1/uiw/projects/{projectId}/comments',
        postComment: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/comments',
        getActivities: '/api/v1/uiw/projects/{projectId}/activities.json?page={pageNumber}',
        projectDetail: '/api/v1/uiw/projects/{projectId}',
        setStoryEstimates: '/api/v1/uiw/projects/{projectId}/stories/{storyId}.json',
        // storyId change--1 on estimate
        startTimer: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/start_timer',
        // storyId change--2 on start timer
        stopTimer: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/stop_timer',
        // storyId change--3 on stop timer
        storyStatus: '/api/v1/uiw/projects/{projectId}/stories/{storyId}',
        // storyId change--4 on change story status
        uploadDesign: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/documents',
        deleteDesign: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/documents/{documentId}',
        getStrories: '/api/v1/uiw/projects/{projectId}/stories.json?all_stories={allStory}',
        getProjectNotifications: '/api/v1/uiw/projects/{projectId}/notifications?page={pageNumber}',
        getAllNotifications: '/api/v1/uiw/notifications.json?page={pageNumber}',
        getStoryInfo: '/api/v1/uiw/projects/{projectId}/stories/{storyId}',
        getCommentsNumber: '/api/v1/external/projects/{projectId}/comments',
        createCommentTracker: '/api/v1/external/projects/{projectId}/comments',
        singleComment: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/comments/{commentId}',
        builder360: 'api/v1/tracker/projects/{projectId}/get_phases',
        pollingApi:'/api/v1/uiw/projects/{projectId}/fetch_status',
        deleteDesignVariant:'/api/v1/uiw/projects/{projectId}/stories/{storyId}/documents/{documentId}',
        readSketchUrl:'/api/v1/projects/{studio_Buildcard}/sketch_files',
        reOrder:'/api/v1/uiw/projects/{project_id}/stories/reordering_list',
        multiUpload:'/api/v1/uiw/projects/{project_id}/stories/{story_id}/documents/multi_upload.json',
        checkArtBoardName:'/api/v1/uiw/projects/{project_id}/stories/{storyId}/validate_artboard_name?artboard_name={artboard_name}',
        promotingDocument:'/api/v1/uiw/projects/{project_id}/stories/{builder_feature_id}/documents/{document_id}/promote',
        markAsRead: '/api/v1/uiw/projects/{project_id}/notifications/mark_as_read',
        mentions:'/api/v1/uiw/projects/{project_id}/users',
        getStoryForFilter: '/api/v1/uiw/projects/{projectId}/stories.json?without_child_stories=true',
        featureNotes:'api/v1/now/projects/all_feature_details/{build_card_Id}',
        getYoutubeData: '/api/v1/uiw/youtube_apis/video_details?id={video_id}',
        getWhiteBoard:'/api/v1/uiw/whiteboards?build_card_id={studio_build_card_id}',
        saveWhiteBoard:'/api/v1/uiw/whiteboards?build_card_id={studio_build_card_id}',
        getSketchForStory:'/api/v1/uiw/projects/{project_Id}/client_sketches',
        setSketchForStory:'/api/v1/uiw/projects/{project_Id}/update_client_sketch',
        deleteAttachment:'/api/v1/uiw/attachments/{attachment_id}',
        maintenance:'/api/v1/uiw/maintenances',
        readNotes:'/api/v1/uiw/maintenances/mark_read_release_notes',
        readMaintenance:'/api/v1/uiw/maintenances/mark_read_schedule_maintenances',
        projectDetails360: 'api/v1/now/projects/project_details/{studio_build_card_id}',
        videoTutorials: '/api/v1/uiw/video_tutorials',
        videoErrorTypes: '/api/v1/uiw/video_tutorials/videos_with_error_types',
        internalDocuments: '/api/v1/now/projects/projects_internal_folder_and_files_listing/{build_card_id}',
        getRecentChanges: '/api/v1/uiw/projects/{project_id}/stories/recent_changes?platform_name={platform}',
        readRecentChanges: '/api/v1/uiw/projects/{project_id}/stories/{story_id}/mark_changes_read',
        deliverDesignToCustomers: '/api/v1/uiw/projects/{project_id}/send_to_review',
        getBaseSketches: '/api/v1/uiw/projects/{project_id}/base_sketches?platform_name={platform}',
        updateFeatureStory: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/documents/{documentId}',
        setPrimary: '/api/v1/uiw/build_card_features/{featureId}/set_primary_image.json',
        // UIW Now API's
        getFeatures: '/api/v1/uiw/build_card_features.json?build_card_id={buildCardId}&platform={platform}&device={device}',
        uiwConfig: '/api/v1/uiw/configurations.json',
        editClickableItem: '/api/v1/uiw/build_card_hotspots/{hotspotId}/clickable_items/{clickableItemId}.json',
        createHotspot: '/api/v1/uiw/build_card_hotspots.json',
        getSingleFeature: '/api/v1/uiw/build_card_features/{storyId}.json?build_card_id={buildCardId}&device={device}&platform={platform}',
        updateFeature: '/api/v1/uiw/build_card_features/{featureId}.json',
        deleteHotspot: '/api/v1/uiw/build_card_hotspots/{hotspotId}.json',
        deleteClickableItem: '/api/v1/uiw/build_card_hotspots/{build_card_hotspot_id}/clickable_items/{clickable_item_id}.json?build_card_id={studio_build_card_id}',
        restartStory: '/api/v1/uiw/build_card_features/{featureId}/set_default_image.json',
        readSketchUrlFeature:'/api/v1/features/{studio_feature_Id}/sketch_files.json',
        branding:'/api/v1/uiw/build_cards/{studio_build_card_id}/branding_details.json',
        deleteScreen: '/api/v1/uiw/build_card_features/{build_card_feature_id}.json?build_card_id={studio_build_card_id}',
        hotSpotPlatform:'/api/v1/uiw/build_card_features/{id}/fetch_platform.json?build_card_id={build_card_id}&build_card_hero_image_id={build_card_hero_image_id}',
        getPlatforms: '/api/v1/uiw/build_cards/{studio_build_card_id}/platforms.json',
        createVariant: '/api/v1/uiw/build_card_features.json',
        deleteVariant:'/api/v1/uiw/build_card_hero_images/{id}.json',
        setAsLaunchScreen:'/api/v1/uiw/build_cards/{build_card_id}/set_launch_screen.json',
        updatePlatform: '/api/v1/uiw/sketch_details/{sketch_detail_id}.json',
        subStoryLinking:'/api/v1/uiw/build_card_features/{parent_feature_id}/link_custom_feature.json',
        unlinkSub:'/api/v1/uiw/build_card_features/{parent_feature_id}/unlink_custom_feature.json',
        platformSwapping: '/api/v1/uiw/build_card_features/{build_card_feature_id}/swap_platforms.json',
        hotSpotRefactorApi:'/api/v1/uiw/build_card_features/{build_Card_feature_id}/image_details.json?build_card_hero_image_id={build_card_hero_image_id}&build_card_id={build_card_id}',
        story_Search_Filter:'/api/v1/uiw/projects/{project_id}/stories/search',
        getDlsUsage: '/api/v1/uiw/projects/{project_id}/stories/dls_metrics',
        getDestinationData: '/api/v1/uiw/build_card_hero_images/search.json?build_card_id={buildCardId}&platform={platform}&device={device}',
        getFigmaVersionMismatch: '/api/v1/uiw/projects/{projectId}/stories/{storyId}/compare_with_figma_node',
        resolvedByUser: '/api/v1/uiw/projects/{project_id}/comments/{commentId}/mark_resolve_unresolve',
        // Builder Now API's
        savePlatform: '/api/v1/uiw/brandings/upload.json',
        copyRole:'/api/v1/uiw/build_cards/{build_card_id}/replicate_with_role.json',
        getBlocks: '/api/v2/features?builder_studio_feature_ids={stduio_feature_id}&studio_type=studio_pro&ss_app_type=null&all_blocks=true',
        toggleNavHotspot: '/api/v1/uiw/build_cards/{studio_build_card_id}/toggle_nav_hotspots_required',
        requestToPromoteSketch: '/api/v1/uiw/feature_sketches',
        compareFeatureSketch: '/api/v1/uiw/feature_sketches/{feature_sketch_id}/compare',
        approveSketch: '/api/v1/uiw/feature_sketches/{requested_sketch_id}/approve',
        rejectSketch: '/api/v1/uiw/feature_sketches/{requested_sketch_id}/reject',
        triggerCodeGeneration: '/api/v1/uiw/build_card_features/{build_card_feature_id}/trigger_code_generation',
          //FBL
        feedBackLoop:'features/user_stories?user_story_id={user_story_id}',
        fetch_feature_dlsFDL:'/api/v1/external/features/fetch_feature_dls?{cmt_feature_id}={id}',
        reqToPromote:'/api/v1/uiw/story_approvals',
        relatedStories :'/api/v1/uiw/projects/{project_id}/stories/{story_id}/related_stories?node_id={node_id}',
        setHero:'/api/v1/uiw/build_card_hero_images/{bchId}/set_main_image.json?device={device}&platform={platform}',
        bChat :'api/v1/tracker/projects/{builder360Id}/chat_workspace?email={userEmail}',
        figmaAccess:'/api/v1/uiw/projects/{project_id}/figma_access_request',
        finishedStories:'/api/v1/uiw/projects/{project_id}/stories/fetch_delivered_stories',
        journey:'/api/v1/uiw/projects/{project_id}/journeys.json',
        getBranding: '/api/v1/uiw/build_cards/{buildCardId}/branding_details.json',
        //Journey Apis
        getProjectMetric:'/api/v1/uiw/projects/{project_Id}?journey=true',
        getAllJourneys:'/api/v1/uiw/projects/{project_Id}/project_journeys',
        getSingleJourney :'/api/v1/uiw/projects/{project_id}/journeys/{journey_id}',
        getAllStoriesWithFilter: '/api/v1/uiw/projects/{project_id}/project_journeys/{journey_id}/fetch_stories_and_images.json'
    };
    static userUpload = {
        aspectRatioError: "Looks like your image is a different ratio to the screen. Please check it before you upload!"
    };

    static devicePlatformMapping = {
        mobile: ['android' ,'ios','mobile site'] ,
        web: ['web' , 'windows', 'macos'] ,
        watch:['watchos']
    };  

    static guildLineDoc = {
        sketch: 'https://docs.google.com/document/u/1/d/e/2PACX-1vQVfbmIR7eilBZ-FaS0TEGSOCsijePQFE2agH6sg9qIscNjO7M0J8ecQsmKUHCalA/pub',
        figma: 'https://docs.google.com/document/d/e/2PACX-1vR3oHJR12x3AVU7d-TbTbFY9ID4mVWMqz_eaNBzweLwy5bXGUTlPlBc38n-MlA8C-2U-S12VCs4ces1/pub'  
    }
    
    //Key to distinguish btw existing hotspots vs new extracted hotspots from sketch layers
    static guidSketchExtractedHotspots = '679af386-784a-4c74-a1b7-3f8202e1704c';
    static layer = "Builder_Design_Layer";
    static perPageProject = '20';
}

export enum status {
    rejected = "rejected",
    accepted = "accepted",
    finished = "finished",
    awaited  = "awaited",
    started = "started",
    unscheduled ="unscheduled",
    delivered ="delivered",
    sketchRejected ="sketch_rejected"
  }

export const startStoryConfirmationConfig = {
    sketch: {
        heading: 'You are ready to start this user story',
        beforeStartText: `Before you start, we want to ensure you're ready. If there are any concerns with below, please reach out to your productologist. Please confirm:`,
        checkPoints: [
            {
                key: 'understandUseCases',
                value: 'You have read and understood the use cases to be covered in the user story.'
            },
            {
                key: 'downloadSketchFile',
                value: 'You have downloaded the sketch file and will be making edits to it.'
            }
        ]
    },
    figma: {
        heading: 'You are ready to start this user story',
        beforeStartText: `Before you start, we want to ensure you’re ready. If there are any concerns, reach out to your Productologist. Please confirm you have:`,
        checkPoints: [
            {
                key: 'understandUseCases',
                value: 'Read and understood the use cases covered in this user story.'
            },
            {
                key: 'getFigmaBoardAccess',
                value: "'Edit access' on the linked Figma board."
            },
            {
                key: 'nodesPrePopulatedCheck',
                value: 'Pre-populated screens per feature, role and device on the Figma board.'
            }
        ]
    }
};
  

export const FeedBackLoopConstants = {
    descriptionIterationObject: {
        number:2,
        keysCmt:['as_a','i_want','so_that'],
        keysTracker:['description_as_a','description_i_want','description_so_that']
    },
    acceptanceCriteriaIterationObject: {
        number:3,
        keysCmt:['title','given','when','then'],
        keysTracker:['title','description_for_given','description_for_when','description_for_then']
    }
};

export const journeyConstants = {
    storyStautsMapping: [
        {
            display: 'Not picked',
            id: 0,
            type: 'story_Status',
            value: 'Not picked'
        },
        {
            display: 'Work in progress',
            id: 1,
            type: 'story_Status',
            value: 'started'
        }, {
            display: 'Pending for review',
            id: 2,
            type: 'story_Status',
            value: 'delivered'
        }, {
            display: 'Accepted',
            id: 3,
            type: 'story_Status',
            value: 'accepted'
        }, {
            display: 'Rejected',
            id: 4,
            type: 'story_Status',
            value: 'rejected'
        }
    ],
    filterDropDownConstants :['isPlatforms', 'isRoles', 'isStoryStatus', 'isSprints'],
    rightPanelDropDownMapping : {
        storyStatus: 'isCollapsibleStoryStatus',
        sprint: 'isCollapsibleSprint',
        role: 'isCollapsibleRole',
        device: 'isCollapsibleDevice'
    }
}
