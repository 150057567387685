import { Constants } from '@shared/services/constants';
import {Injectable, ViewChild} from "@angular/core";
import { AsyncSubject, BehaviorSubject, Subject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { UiwDataServiceService } from "./uiw-data.service";
import { CommentModel } from "../models/comment.model";
import { RectangleComponent } from "@shared/components/manual-hotspot/rectangle-component/rectangle-component";
import { ManualHotspotService } from "@shared/components/manual-hotspot/manual-hotspot.service";
import { ToasterService } from '@core/toaster.service';
import {journeyConstants, status, uiwConstants} from "@shared/services/uiw-Constants";
import { WindowRefService } from "@core/window-ref.service";
import { BaseService } from "@core/base.service";
import { environment } from "@env/environment";
import { CookieService } from "@core/cookie.service";
import { AuthService } from '@core/auth.service';
import { DataCommService } from './data-comm.service';
import { PreviewModel } from '../models/preview.model';

@Injectable({
    providedIn: 'root'
})
export class UiwPhaseService {
    isWheelEventDisabledOnPanZoom;
    currentProject;
    isCommentBoxOpen = {};
    currentProjectComments;
    commentsDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    singleCommentSelectSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    commentModeSelectorSubject: Subject<number> = new Subject();
    getAllStoriesSubject: Subject<any> = new Subject();
    uploadedDesignUrlSubject: BehaviorSubject<string> = new BehaviorSubject('');
    getStoryEstimateSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    getClickPanzoomSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    storyMappingFromFlowChart: BehaviorSubject<any> = new BehaviorSubject(null);
    currentHotspotSelectedForEditing: BehaviorSubject<any> = new BehaviorSubject(null);
    clickableItemsList: BehaviorSubject<any> = new BehaviorSubject(null);
    selectSvgOnHotspotClick: Subject<any> = new Subject();
    recentChangesSubject: Subject<any> = new Subject();
    clickedOutSideSubject: Subject<any> = new Subject();
    savedEditedData;
    isEditMode;
    isCreateMode;
    selectedFeatureForHotspotEdit: Subject<any> = new Subject();
    callEditHotspotSubmit: Subject<boolean> = new Subject();
    editPositionOfHotspot: Subject<any> = new Subject();
    clickableItemsArray = [];
    featureResponse;
    updateHotspotData: BehaviorSubject<any> = new BehaviorSubject(null);
    createHotspotsUpdate: Subject<any> = new Subject();
    storyDetailing: BehaviorSubject<any> = new BehaviorSubject(null);
    countdownSubject: BehaviorSubject<string> = new BehaviorSubject('');
    sideBarWidth: string;
    flowChartWidth;
    flowChartLeft;
    leftFlowChartPos;
    briefBlockWidth;
    featureNotesBlockWidth;
    internalDocumentsBlockWidth;
    commentsNumberData = [];
    singleComment;
    updateReplyInComments: BehaviorSubject<any> = new BehaviorSubject(null);
    updateAttachmentsOnStoryDetail: BehaviorSubject<any> = new BehaviorSubject(null);
    updateView: BehaviorSubject<any> = new BehaviorSubject(null);
    getFrameData: BehaviorSubject<any> = new BehaviorSubject(null);
    featureIdForEditEta: BehaviorSubject<any> = new BehaviorSubject(null);
    showHotspotEditFeature: boolean;
    activateOverlay: boolean;
    onboardingSteps: number;
    storyOnboardingSteps: number;
    deleteAttachmentSubject = new Subject<any>();
    rectanglesLoaded: Subject<boolean> = new Subject();
    customHotspotSaveSubject: Subject<RectangleComponent> = new Subject();
    showHideDeletePopup: boolean;
    currentHotspotId;
    clickableItemToBeDeleted = 0 ;
    createHotspotSubject: Subject<any> = new Subject();
    featureVersions;
    versionHeroImg: Subject<string> = new Subject();
    fileUploadSubject: Subject<any> = new Subject();
    fileUploadErrorSubject: Subject<any> = new Subject();
    studioftrId;
    selectedFeature;
    updateStoryStatus: BehaviorSubject<any> = new BehaviorSubject(null);
    isRightPanelVisible: boolean = true;
    isSidebarCollapsed: boolean = false;
    canStoryBeFinished = {};
    zoomLevelOnStoryOnboarding: Subject<boolean> = new Subject();
    disablePanzoomOnSkip: Subject<boolean> = new Subject();
    updateSingleFeature: Subject<any> = new Subject();
    isComponentLoded = false;
    currentViewSelectedOnRightPanel:any;
    allStoriesData =[];   
    isStoriesDataLoaded:boolean =false;
    featureSubject: Subject<any> = new Subject();
    storyDetailRouteParams;
    storyDetailRouteState;
    uploadStoryId;
    attachmentsArray = [];
    attachmentsArraySubject: Subject<any> = new Subject();
    elapsedSeconds = 0;
    timerWidth;
    countDownInterval;
    countdownObj = {};
    fitToZoomOnCommentsPanel: Subject<boolean> = new Subject();
    storyDetailOnBoardingSteps : number;
    storyDetailstoryOnboardingSteps :number;
    featureResponseSuccess: Subject<boolean> = new Subject();
    customPrototypeFlag = false;
    isEditEstimateVisible = {};
    oldEstimate = {};
    etaInputModel = {};
    sourceScreenForHotspot: string;
    onboardingActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isWheelEventDisabledOnPanZoomDetail = true;
    baseSketchFiles = {};
    unlinkedFeatures =[];
    toggleUnlink;
    toggleImageSync;
    isMappedForFirstTime;
    //Behaviour subjects are created for cases of status accepted to pass data of main story and right panel;
    rightSideMappingForSketch: BehaviorSubject<boolean> = new BehaviorSubject(null);
    leftSideMappingForSketch: BehaviorSubject<boolean> = new BehaviorSubject(null);
    notificationPanel = false;
    unreadCount = 0;
    notificationSource = new Subject();
    notificationSource$ = this.notificationSource.asObservable();
    wheelDisabled = new Subject();
    wheelDisabled$ = this.wheelDisabled.asObservable();
    clickedNotification :any={};
    notificationSubscribtion: BehaviorSubject<boolean> = new BehaviorSubject(null);
    fitToZoom = false;
    //mentions data
    showMentions =false;
    allMentions =[];
    // comment filter
    showFilters =false;
    clonedComments=[];
    allAppliedFilters = [];
    resolvedCheckbox = false;
    allFeatures = [];
    allStoryForFilter: any = [];
    clickedAllCheckBox:boolean =false;
    commentSubOnAdd: BehaviorSubject<boolean> = new BehaviorSubject(null);
    allMentionsForFilter =[];
    //all errors for sketch in comments panel
    commentErrorHeading=[
        {title: 'image_not_found_error',value: "Image not found or disabled for the following elements"}, 
        {title:'no_mapping',value: "No Mapping found for the following elements"}, 
        {title:'invalid_prefix_mapping',value: "Incorrect prefix for the following elements"}, 
        {title:'elements_error',value: "Mandatory Layers not found for the following elements"},
        {title:'mapping_error',value:"No Mapping found for the following elements"},
        {title:'sketch_layer_error',value:"Sketch Layer Error"},
        {title:'sketch_page_error',value:"Sketch Page Error"}
    ]
    
    showPopup =false;
    openArtBoardPopup =false;
    deletePopup= false;
    releaseNotes:any;
    scheduleMaintenance:any;
    showFeatureNotes = false;
    ApiCalledForReleaseNotes: boolean;
    showMaintenance: boolean =false;
    pastedHotSpot:any;
    // closePastePopup: boolean;
    copiedValue =0;
    highlightFeatureByStoryId: BehaviorSubject<number> = new BehaviorSubject(null);
    // sessionCountForCtrlZ = false;
    ctrlZ : BehaviorSubject<boolean> = new BehaviorSubject(false);
    // callCtrlZSub =false;
    commentError :any;
    showErrorPopup = false;
    //MERGINGTRACKER-17
    currentProjectPlatforms :any;
    selectedPlatformForTracker: any;
    isHotspotHorizontalScroll: boolean = false;
    isHotspotVerticalScroll: boolean = false;
    hotspotImageScrollX: number = 0;
    hotspotImageScrollY: number = 0;
    hotspotImageScale: number = 1;
    hotspotLinkingWrapperStyleObj: any = {};
    previewPerfectScrollBarConfig: any = {
        suppressScrollX: true,
        suppressScrollY: true
    };

    sketchFiles =[];
    sfIdNullFeature = [];
    subStoryPopup =false;
    featureInfoForSubStory :any;
    allFeatureForTitleMapping = [];
    allNotificationForPaltform =[];
    showDeallocatedPopup = false;
    deallocationText: string;
    previewHotspotHighlight: Subject<boolean> = new Subject();
    previewHotspotHighlight$: Observable<boolean> = this.previewHotspotHighlight.asObservable();
    showPreviewHotspotAnimation: boolean = true;
    tsId;
    isPreviewWebImgMoveToCenter: boolean = false;
    visual_qa_image: string;
    openVisualQAPopup: boolean;
    designReadyToSend: number = 0;
    isVisualQaExist: boolean;
    is_full_build: boolean;
    is_prototype:boolean;
    is_profressional:boolean;
    guid: any;
    studioFeatureBaseSketches: any = {}
    roles=[];
    selectedRole:any;
    visual_qa_err_msg: string = '';
    visual_qa_story_id: string = '';
    visual_qa_story_title: string = '';
    currentDevicePlatforms: any = {};
    mappingDone = false;
    mappingStory = new Subject();
    screenName: string = '';
    navigateToProjectsPage: boolean = false;
    isStartStoryConfirmationOverlay: boolean = false;
    startStoryConfirmation: Subject<any> = new Subject();
    designType: string = '';
    designTypeIconPath: string = '';
    figmaBoardUrl: string = '';
    isToggleStorySummaryPopup: boolean = false;
    storySummaryData: any;
    openConfirmationPopup: boolean = false;
    unlinkedPanelFigmaInfoTooltipStyle: any = {
        isVisible: false,
        left: 0,
        top: 0
    };
    allFeature: BehaviorSubject<any> = new BehaviorSubject(null);
    sprints :Array<any> = [];
    splitStoryObject :Object;
    uploadSketch: BehaviorSubject<any> = new BehaviorSubject(null);
    storyOperationOverlay: Subject<any> = new Subject();
    dimenson:Boolean;
    storyType: string = '';
    isPlayPauseDisabled: boolean = false;
    openDlsUsagePopup: boolean = false;
    currentDlsUsage: any;

    openFeedbackPopup :Boolean = false;
    currentDesign:any;
    dynamicTextForFDL:string;
    dynamicIdForFDL:string|number;
    socketSub: any;
    currentClient: any;
    isPromoted ={};
    disabledPromote: boolean;
    promoteObj={};
    currentActiveStory: any = {
        id: null,
        builder_feature_id: null,
        title: '',
        owner_email: '',
        estimate: 1,
        timer_elapsed_total: 10,
    };
    isCurrentActiveStoryPresent: boolean = false;
    selectedItem: any = {};
    allDestinationData: any;
    studioPlatformIds: any;
    isInitialDesLoaded:boolean = false;
    pageCount: number = 1;
    per_page: number = 20;
    flatBchiGroupArray =[];
    errorDetails =[];
    createGroupFlag: boolean = false;
    showFullScreenPopup: boolean;
    currentActiveStorySubject: Subject<any> = new Subject();
    isOpenSwapModal: boolean = false;
    subStoryCount = 0;
  variantOpenFeature: any;
  confirmFeedBackPopup: boolean;
    orderedDocuments: any = [];
    currentFigmaMismatchData: any = {};
    isShowFigmaMismatchPopup: boolean = false;
    story_Id: string;
    figmaMismatchObject= { 
    figma_validation: null,
    showPopup: false, stepNumber: 0,
    misMatchData :{}
    };

  hideStoryOverlay: boolean = false;
  selectedFeatureBchId;
    hoverOnHotspot: boolean;
  virtualFeature: any;
  searchObject = {
    showCount:false,
    currentCount: 0,
    totalCount:0
  }
    transitionInProgress: boolean;
    isPlatform_Role_Updated: boolean = false;
    builder360Id;
    bchatDataObject: any;
    isOpenFigmaAccessPopup: boolean = false;
    bchatSettings  = {
        env:  environment.bchat_env,
        apiEndpoint: environment.apiEndpointBchat,
        apiVersion:  'v1',
        channelId:  '',
        workspaceId:  '',
        senderId:  '',
        authenticationToken: environment.authenticationToken,
        }
    uploadBtnClicked: boolean = false;
    isOpenSendCustomerPopup: boolean = false;
    allFinishedStories = [];
    finishedStoryCount = 0;
  blockFlowChart: boolean;
  clonedCommentsRight: any = [];
    figmaAllocation: any;
    popupComment: CommentModel;
    showCommentPopup: boolean;
    clonedFinalArray = [];
    featureOfPopupComment: any;
    allMentionsForCustomer = [];
    journeyFeatureNotes = {};
    isOpenJourneyDetailPanel: boolean = false;
    selectedJourney: any;
    platformsArr: any = [];
    momentRelativeTime = {
        future: 'in %s',
        past: '%s ago',
        s: 'seconds',
        ss: '%ss',
        m: 'a minute',
        mm: '%dm',
        h: 'an hour',
        hh: '%dh',
        d: 'a day',
        dd: '%dd',
        M: 'a month',
        MM: '%dM',
        y: 'a year',
        yy: '%dY'
      }
    journeyCanvasSub: BehaviorSubject<any> = new BehaviorSubject(null);
    addPrimarySub: Subject<any> = new Subject();
  dataLoader: boolean = true;
  journeysArrayForHeader = [];
  selectedJourneyForHeader: any;
  currentRunningStoryDataJourney: any;
  isJourneyChange: boolean;
  journeyFeature: any;
    dev_release_at :any;
    rolePlatformMapping: any[];
    clonedJourneyStoriesRightPanel = [];
    clonedRolePlatformMapping: any;
    tabSwitchSubOnRightPanel: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(private router: Router,
        private rectangleService: ManualHotspotService,
        private uiwDataService: UiwDataServiceService,
        private toasterService: ToasterService,
        private window: WindowRefService,
        public baseService: BaseService,
        public cookieService: CookieService,
        public authService :AuthService,
        private dataCommService: DataCommService,
        ) { }


    getViewStatus(): Observable<any> {
        return this.updateView.asObservable();
    }

    setViewStatus(value: any) {
        this.updateView.next(value);
    }

    togglePanZoomWheelEvent(flag) {
        this.isWheelEventDisabledOnPanZoom = flag;
      //  this.wheelDisabled.next(true);
    }

    isUiWorkspace() {
        return this.router.url.includes('uiworkspace');
    }

    isUiWPreview() {
        return this.router.url.includes('preview');
    }

    isUiwBrief() {
        return this.router.url.includes('brief');
    }

    isFeatureNotes() {
        return this.router.url.includes('featureNotes');
    }

    isUiwHelp() {
        return this.router.url.includes('help') || this.router.url.includes('videoTutorials') ;
    }
    isWhiteBoard() {
        return this.router.url.includes('whiteboard');
    }

    isApeiros() {
        return this.router.url.includes('apeiros');
    }

    isInternalDocuments() {
        return this.router.url.includes('internalDocuments');
    }

    isProjectPage() {
        return this.router.url.includes('projects');
    }

    isJourneyPage() {
        return this.router.url.includes('journeys');
    }

    isFlowchartPage() {
        return this.router.url.includes('flowchart');
    }

    getStoryIdForMapping(): Observable<string> {
        return this.storyMappingFromFlowChart.asObservable();
    }

    setStoryIdForMapping(storyId: string) {
        this.storyMappingFromFlowChart.next(storyId);
    }

    getStoryDetail(): Observable<string> {
        return this.storyDetailing.asObservable();
    }

    setStoryDetail(storyDetail: string) {
        this.storyDetailing.next(storyDetail);
    }


    getCommentData(comment) {
        this.uiwDataService.getCommentData(this.currentProject.id, comment)
            .subscribe(
                (data: any) => {
                    this.singleCommentSelectSubject.next(data);
                },
                error => {
                    console.log(error);
                }
            );
    }

    getAttachments(): Observable<any> {
        return this.updateAttachmentsOnStoryDetail.asObservable();
    }

    setAttachments(attachment: any) {
        this.updateAttachmentsOnStoryDetail.next(attachment);
    }

    checkOnboardingActive() {
        const status = localStorage.getItem('onboarding') === 'true';
        return status;
    }

    goToNextOnboardingStep() {
        if (this.onboardingSteps === 5) {
            this.onboardingSteps = undefined;
            this.activateOverlay = false;
            localStorage.setItem('onboarding', 'false');
            localStorage.setItem('story_onboarding', 'true');
            this.disablePanzoomOnSkip.next(true);
        } else {
            if(this.onboardingSteps == 8){
                this.onboardingSteps += 2;

            }else {
                this.onboardingSteps += 1;
            }
        }
    }

    // goToNextStoryOnboardingStep() {
    //     if (this.storyOnboardingSteps === 6) {
    //         this.storyOnboardingSteps = undefined;
    //         this.activateOverlay = false;
    //         localStorage.setItem('story_onboarding', 'false');
    //         this.disablePanzoomOnSkip.next(true);
    //     } else {
    //         this.storyOnboardingSteps += 1;
    //     }
    // }

    goToNextOnboardingStepStoryDetail() {
        this.storyDetailOnBoardingSteps +=1;
    }

    skipOnboardingStoryDetail(event) {
        event.stopPropagation();
        localStorage.setItem('onboardingStoryDetailFirst', 'false');
        localStorage.setItem('story_onboardingDetailSecond', 'true');
        this.storyDetailOnBoardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    // goToNextStoryOnboardingStepStoryDetail() {
    //     if(this.storyDetailstoryOnboardingSteps == 6){
    //    this.skipOnboardingStoryDetailInnerFlow();
    //     }else {
    //         this.storyDetailstoryOnboardingSteps += 1;
    //     }
    // }
    // skipOnboardingStoryDetailInnerFlow(event?) {
    //     if(event) {
    //         event.stopPropagation();
    //     }
    //     localStorage.setItem('onboardingStoryDetailFirst', 'false');
    //     localStorage.setItem('story_onboardingDetailSecond', 'false');
    //     this.storyDetailstoryOnboardingSteps = undefined;
    //     this.activateOverlay = false;
    //     this.disablePanzoomOnSkip.next(true);
    // }

    skipStoryOnboarding(event) {
        event.stopPropagation();
        localStorage.setItem('story_onboarding', 'false');
        this.storyOnboardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    skipOnboarding(event) {
        event.stopPropagation();
        localStorage.setItem('onboarding', 'false');
        localStorage.setItem('story_onboarding', 'true');
        this.onboardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    resetOnboarding() {
        localStorage.removeItem('onboarding');
        localStorage.removeItem('story_onboarding');
        localStorage.removeItem('onboardingStoryDetailFirst');
        window.location.reload();
    }

    openHotspotDeletePopup(hotspotId, designerCreatedHotspot, clickableItemId) {
        this.currentHotspotId = hotspotId;
        this.clickableItemToBeDeleted = 0;
        if (!designerCreatedHotspot && clickableItemId) {
            this.clickableItemToBeDeleted = clickableItemId;
        }
        this.showHideDeletePopup = true;
    }

    closeHotspotDeletePopup() {
        this.showHideDeletePopup = false;
    }

    isChangeVersionView() {
        return this.router.url.indexOf('editversion') > -1;
    }

    getIdMappingForEditEta(): Observable<any> {
        return this.featureIdForEditEta.asObservable();
    }

    setIdMappingForEditEta(featureId: any) {
        this.featureIdForEditEta.next(featureId);
    }
    setAttachmentId(object: any) {
        this.deleteAttachmentSubject.next(object);
    }

    clearAttachmentId() {
        this.deleteAttachmentSubject.complete();
    }

    getAttachmentId(): Observable<any> {
        return this.deleteAttachmentSubject.asObservable();
    }

    getStoryStatus(): Observable<any> {
        return this.updateStoryStatus.asObservable();
    }
    setStoryStatus(status: any) {
        this.updateStoryStatus.next(status);
    }

    openRightPanel() {
        if (!this.isRightPanelVisible) {
            this.isRightPanelVisible = true;
        }
    }

    downloadSketchFile(event, url, trackEventObj?) {
        event?.stopPropagation();
        if (trackEventObj?.cta === 'sampleSketchFile') {
            this.dataCommService.trackEvent(`sample_${this.designType.toLowerCase()}_files_downloaded`, {
                user_id: this.dataCommService.userUniqueid,
                buildcard_id: this.currentProject.build_card_id,
                feature_id: trackEventObj?.studioFeatureId,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice
            });
        }
        window.open(
            url,
            '_blank'
        );
      //  window.location.href = url;
    }

    /**
     * Function to download the attachment files
     * @param {MouseEvent} event - MouseEvent
     * @param {string} refUrl - File Reference Url
     * @param {string} refName - File Reference Name
     */
    downloadAttachmentFiles(event: MouseEvent, refUrl: string, refName: string): void {
        if (event) {
            event.stopPropagation();
        }
        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open("GET", refUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            let urlCreator: any = window.URL || window.webkitURL,
                fileUrl: string = urlCreator.createObjectURL(this.response),
                tag: HTMLAnchorElement = document.createElement('a');
            tag.href = fileUrl;
            tag.download = refName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.onloadend = () => {
            this.toasterService.success('1 file downloaded');
        }
        xhr.send();
    }

    downloadFigmaPngFile(event, imgUrl) {
        event.stopPropagation();
        let temp: any = imgUrl.split('/'),
            imageName: string = temp[temp.length - 1],
            xhr = new XMLHttpRequest();
            
        xhr.open("GET", imgUrl, true);
        xhr.responseType = "blob";
        
        xhr.onload = function () {
            let urlCreator = window.URL || window.webkitURL,
                imageUrl = urlCreator.createObjectURL(this.response),
                tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = imageName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
    }

    useBaseSketchFile(baseSketchUrl?) {
        this.dataCommService.trackEvent('base_sketch_file_downloaded' , {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            project_id: this.currentProject?.id
        });
        window.open(
            baseSketchUrl ? baseSketchUrl : 'https://bstudiouiwimages.blob.core.windows.net/base-sketch/Baseline.sketch',
            '_blank'
        );
    }

    filterFeature(feature) {
        if(feature){
            if (feature && feature.story && feature.story.wireframe){
                return null;
             }
             else{
                 let filteredFeature = feature.build_card_hero_images && feature.build_card_hero_images.find(e => e.is_primary);
                 if (filteredFeature) {
                     return filteredFeature;
                 }
             }
        }
    }

    inspirationFilterFeature(feature) {
        if (feature) {
            if (feature && feature.story && feature.story.wireframe) {
                return null;
            }
            else {
                if (feature?.base_sketch?.figma_image_url) {
                    return feature;
                }
            }
        }
    }

    showToastForAllocation(dateExist, from?) {
        this.navigateToProjectsPage = true;
        if (dateExist) {
            this.deallocationText = Constants.toasterMessage.PROJECT_DEALLOCATION_WITH_DATE.replace('{dateExist}', dateExist);
        }
        else if (from) {
            if (from === 'edit') this.deallocationText = Constants.toasterMessage.SKIP_HOTSPOT_MAPPING;
            else if (from === 'editScreenName') {
                this.navigateToProjectsPage = false;
                this.deallocationText = Constants.toasterMessage.SCREEN_NAME_CHANGE;
            };
            setTimeout(() => {
                this.clearText();
            }, Constants.clearTime);
        }
        else {
            this.deallocationText = Constants.toasterMessage.PROJECT_DEALLOCATION_WITHOUT_DATE;
        }
    }

    clearText(){
        this.showDeallocatedPopup =false;
        this.deallocationText ='';
    }

    isCustomPrototype(): boolean {
        return this.currentProject && this.currentProject.is_full_build === true && !this.customPrototypeFlag;
    }
    sketchUploadCase(story) {
        // storyStatusChange no change method is not in use
        // const mainHeroImage = this.filterFeature(item);
        if (story) {
            return (story.state == 'accepted' || (story.state == 'delivered' || story.state == 'accepted'))  && this.isCustomPrototype();
        }
        return false;
    }
    sketchUploadStoryList(item) {
        const mainHeroImage = this.filterFeature(item);
        if (item && mainHeroImage) {
            return  (item.state == 'accepted' || (mainHeroImage.status == 'approved' && (item.state == 'delivered' || item.state == 'accepted')))  && this.isCustomPrototype();
        }
        return false;
    }

    getNonPrimaryVariantsCount() {
        return this.attachmentsArray.length > 0 && this.attachmentsArray[0].build_card_hero_images.filter(img => !img.is_primary).length;
    }

    getAttachmentsCount() {
        return this.attachmentsArray.length > 0 && this.attachmentsArray[0].story && this.attachmentsArray[0].story.ordered_documents.filter(doc => !doc.is_primary && !doc.uiw_uploaded).length;
    }

    getSketchId() : Observable<any> {
        return this.rightSideMappingForSketch.asObservable();

     }

    setSketchId(storyIdForSketch) {
        this.rightSideMappingForSketch.next(storyIdForSketch);
     }

     getSketchIdForLeft() : Observable<any> {
        return this.leftSideMappingForSketch.asObservable();

     }

    setSketchIdForLeft(storyIdForSketch) {
        this.leftSideMappingForSketch.next(storyIdForSketch);
     }

     deviceMappedWithPlatform(platform) {
        // @ts-ignore
      return Object.keys(uiwConstants.devicePlatformMapping).find(device => {
          return  uiwConstants.devicePlatformMapping[device].find(plat => {
               if (plat == platform) {
                   return device;
               }
            });
        });


        // return Object.keys(uiwConstants.devicePlatformMapping).find(key => uiwConstants.devicePlatformMapping[key].find(platKey => platKey === platform));
     }
  //notification-- subjects
     getNotification(){
        return this.notificationSubscribtion.asObservable();
     }
     setNotification(value){
        this.notificationSubscribtion.next(value);
     }

    getAddCommentStatus(): Observable<any> {
        return this.commentSubOnAdd.asObservable();
    }

    setAddCommentStatus(value: any) {
        this.commentSubOnAdd.next(value);
    }
    closeFilter(){
        this.showFilters = false;
    }
    isIpmExist(){
       return this.router.url.includes('ipm');
    }
    downloadActualWrapClass(item) {
        if (item) {
            const downloadSketchFlag = this.checkExistenceOfActualSketchInLocalStorage(item);
            if (item.story && item.story.id && item.story.client_sketch_id && (item.story.state === 'unscheduled' || item.story.state === 'unstarted') && !downloadSketchFlag) {
                return true;
            } else {
                return false;
            }
        }
    }
    checkExistenceOfActualSketchInLocalStorage(item) {
        let downloadSketchFlag = false;
        const getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
        if (getSketchLocalItem && getSketchLocalItem[this.currentProject.id]) {
                if (item.story && item.story.id) {
                        for (const story in getSketchLocalItem[this.currentProject.id]) {
                            if (story == item.story.id) {
                                downloadSketchFlag = true;
                            }
                        }
                }

        }
        return downloadSketchFlag;
    }
    objectLength(object) {
        let size = 0,
            key;
        for (key in object) {
            if (object.hasOwnProperty(key)) size++;
        }
        return size;
    }
    setDownloadActualSketchFlag(item) {
        let setSketchData = {};
        let getSketchLocalItem = [];

        if (!this.checkExistenceOfActualSketchInLocalStorage(item)) {
            getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
            setSketchData = Object.assign({}, getSketchLocalItem);

            if (this.objectLength(setSketchData[this.currentProject.id]) == 0) {

                setSketchData[this.currentProject.id] = {};
                setSketchData[this.currentProject.id][item.story.id] = 1;
            } else {
                if (setSketchData && setSketchData[this.currentProject.id]) {
                    for (let i = 0; i <= this.objectLength(setSketchData[this.currentProject.id]); i++){
                        if (!setSketchData[this.currentProject.id][item.story.id]) {
                            setSketchData[this.currentProject.id][item.story.id] = 1;
                        }
                    }
                }
            }
            localStorage.setItem('downloadSketch', JSON.stringify(setSketchData));
        }
    }
    removeStoryCookieOnFinish(storyId) {
        const getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
        let presentStory = false;
        if (getSketchLocalItem && getSketchLocalItem[this.currentProject.id]) {
            if (storyId) {
                for (const story in getSketchLocalItem[this.currentProject.id]) {
                    if (story == storyId) {
                      presentStory = true;
                    }
                }
            }
        }
        if (presentStory) {
            delete getSketchLocalItem[this.currentProject.id][storyId];
            localStorage.setItem('downloadSketch', JSON.stringify(getSketchLocalItem));
        }
    }
    openPdf(event){
        event.stopPropagation();
        if(this.designType === 'Figma') {
            window.open(uiwConstants.guildLineDoc.figma, '_blank');
        }
        else {
            window.open(uiwConstants.guildLineDoc.sketch, '_blank');
        }
    }

    
    toggleImageSyncFunc(event) {
        event.stopPropagation();
        if(this.toggleUnlink) this.toggleUnlink = false;
        this.toggleImageSync = !this.toggleImageSync;
        setTimeout(() => {
            if (!this.isRightPanelVisible) this.isRightPanelVisible = true;
        }, 100);
    }


    getFeatureNotes(){
        this.uiwDataService.maintenanceApi().subscribe((res:any)=>{
            if(res && res.release_notes){
              this.ApiCalledForReleaseNotes = true;
              this.showFeatureNotes =true;
              this.releaseNotes = res.release_notes;
              const obj={
                feature_release_items:this.releaseNotes.feature_release_items,
                improvement_release_items:this.releaseNotes.improvement_release_items,
                fix_release_items:this.releaseNotes.fix_release_items
              }
              this.releaseNotes.release_items= obj;
              delete this.releaseNotes.feature_release_items;
              delete this.releaseNotes.improvement_release_items;
              delete this.releaseNotes.fix_release_items;
      
            }
            if(res && res.schedule_maintenance){
              this.showMaintenance =true;
              this.scheduleMaintenance = res.schedule_maintenance;
            }
          })
    }

    // closeHotspotPastePopup(){
    //     this.closePastePopup =false;
    // }    
    getHighlightFeatureByStoryId(): Observable<any>{
        return this.highlightFeatureByStoryId.asObservable();
    }

    setHighlightFeatureByStoryId(featureId: number) {
        this.highlightFeatureByStoryId.next(featureId)
     }

    //  setCtrlZValue(value) {
    //     this.ctrlZ.next(value);
    //  }

    //  getCtrlZValue() : Observable<any> {
    //     return this.ctrlZ.asObservable();
    //  }

     getError(storyObj,mappedFeatureBchid){
     let primaryDocument = this.getDocument(storyObj,mappedFeatureBchid);
     let doc = primaryDocument.document;
     let allDocs = primaryDocument.allDocuments;
      if(doc && doc.sketch_document_id){
         let sketch = allDocs.find(e=> e.id == doc.sketch_document_id);
         if(sketch){
             return sketch.sketch_error;
         }
      }
     }

     private getSketchDocument(storyObj, mappedFeatureBchid: number) {
        const documents = storyObj.ordered_documents ?? [];
        if (documents && documents.length) {
            return { ...documents.find(d => d.hero_image_feature_id == mappedFeatureBchid && !d.content_type && !!d.image_document_id) };
        }
    }

     getDocument(storyObj,mappedFeatureBchid){
         let documents = storyObj.ordered_documents;
         if(document && documents.length > 0){
          return {document:documents.find(d=> d.hero_image_feature_id == mappedFeatureBchid && d.sketch_document_id),allDocuments:documents};
         }
     }
     logOut(){
        localStorage.removeItem('role');
        localStorage.removeItem('rightPanelFilters');
        this.window.nativeDocument.cookie = `${environment.tracker_user}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieService.cookieDomain};`;
        this.window.nativeDocument.cookie = `${environment.tracker_user_beta}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieService.cookieDomain};`;
        window.location.href = this.baseService.redirectionTracker;
     }
     
    getStoryOnBoarding() {
        if (this.storyOnboardingSteps > 0 && localStorage.getItem('story_onboarding') !== null) {
            return false;
        } else {
            return true;
        }
    }

    validateMobileSite(platform: string){
        if(platform && platform.includes('-')){
           return platform = platform.replace('-'," ");
        }
        return platform;
    }

    genericPlatformSetRefresh(currentProject){
         if(this.selectedPlatformForTracker == undefined){
            this.currentProjectPlatforms = currentProject.formatted_platforms;
            this.selectedPlatformForTracker = Object.values(this.currentProjectPlatforms)[0][0].name;
            Object.values(this.currentProjectPlatforms)[0][0]['selected'] = true;  
        }
        this.currentProject.device = this.deviceMappedWithPlatform(this.selectedPlatformForTracker);
        this.setRoleStatus();
    }
    //CATEGORY6-HOTSPOT
    checkForDoc(hotspot,frameData){
             if(hotspot.category_id == 6){
                if(frameData && frameData.story && frameData.story.ordered_documents.length > 0){
                       let is_Primary = frameData.story.ordered_documents.find(e => e.is_primary);
                       if(is_Primary){
                       const document = frameData.story.ordered_documents.find(e => e.content_type == 'application/octet-stream' && e.id == is_Primary.sketch_document_id);
                       if (document)  return false;  else  return true;  
                    }
                }
             }
             return true;
    }
    //CATEGORY6-HOTSPOT
   showDownLoadButton(featureData){
       if(featureData && !featureData?.story?.parent_id){
        let mainHeroImageObj = featureData.build_card_hero_images.find(e => e.is_primary ==  true);
        if(mainHeroImageObj && mainHeroImageObj.hotspots.length > 0) {
           let cat6 = mainHeroImageObj.hotspots.find(e=>e.category_id == 6);
            if(cat6){
                // console.log(cat6.clickable_items);
                return true;
            }
            else{
                return false;
            }
         }
         else{
             return false;
         }
       }
    }

    checkForVisualQA(feature,shouldOpenPopup){
    return ((feature && feature?.story) ? this.checkPrimary(feature,shouldOpenPopup) : false);
    }

    checkPrimary(feature,shouldOpenPopup){
        let is_Primary = feature?.story?.ordered_documents?.find(e => e?.is_primary);
       return ((is_Primary) ? this.checkSketchDocument(is_Primary,feature,shouldOpenPopup) : false );
    }

    checkSketchDocument(primaryDoc,feature,shouldOpenPopup){
       const document = feature?.story?.ordered_documents?.find(e => e?.content_type == 'application/octet-stream' && e?.id == primaryDoc?.sketch_document_id);
      return ((document && document?.visual_qa_story_id) ? this.openPopupForQA(document,shouldOpenPopup) : false);
    }

    openPopupForQA(document,shouldOpenPopup){
        if(shouldOpenPopup){
        this.openVisualQAPopup = true;
        this.visual_qa_err_msg = document.visual_qa_error;
        this.visual_qa_story_id = document.visual_qa_story_id;
        this.visual_qa_story_title = document.visual_qa_story_title;
        this.visual_qa_image = document.visual_qa_image_url;
        }
        return true;
    }

    closeOpenQAPopup(event?){
        if (event) {
            event.stopPropagation();
        }

        this.openVisualQAPopup = false;
        this.visual_qa_image = null;
    }

    noVisualQa(){
        this.isVisualQaExist =false;
    }

    filterPlatform(device){
        return uiwConstants.devicePlatformMapping[device].find(e=> e == this.selectedPlatformForTracker);
    }
        setPreviewHotspotHighlight(flag: boolean): void {
        this.previewHotspotHighlight.next(flag);
    }

    getPreviewHotspotHighlight(): Observable<boolean> {
        return this.previewHotspotHighlight$;
    }

    redirection(){
        this.router.navigateByUrl('uiworkspace/projects');
    }

    showToastTailor(){
        if(!this.is_full_build && this.is_prototype && !this.is_profressional) {
            this.toasterService.error('No design needed for this story');
            return true;
        }
        else {
            return false;
        }
    }

    getCommentMode(): Observable<Number> {  
        return this.commentModeSelectorSubject.asObservable();
    }
    
    getGuid(allUsers){
             let user = this.authService.userProfile?.trackerUserData?.user;
             allUsers.forEach(element => {
                if (element.membership.user.email == user.email) {
                    this.guid = element.membership.user.guid;
                 }
            });
     }

    getBaseSketches(projectId) {
        this.uiwDataService.getBaseSketches(projectId, this.selectedPlatformForTracker,this.getRoleStatus()).subscribe((res: any) => {
            this.studioFeatureBaseSketches = res.base_sketches;
        });
    }

    getRoleStatus(){
        let role;
        if (localStorage.getItem('role') && localStorage.getItem('role') !== 'undefined')
            role = JSON.parse(localStorage.getItem('role'));
        if(this.selectedRole){
            return this.selectedRole.studio_id;
        }
        else{
            if(role){
               return role.studio_id;
            }
        }
        return null;
     }

    setRoleStatus(studioFeatureId?): void {
        if (this.roles?.length === 0) return;

        const storedRoleStr = localStorage.getItem('role');

        if (studioFeatureId) {
            const role = this.roles.find(role => role?.id === studioFeatureId);
            if (role) {
                this.selectRole(role);
                localStorage.setItem('role', JSON.stringify(role));
            }
        }
        else if (storedRoleStr && storedRoleStr !== 'undefined') {
            const storedRole = JSON.parse(storedRoleStr),
                role = this.roles.find(role => role?.studio_id === storedRole?.studio_id && role?.name === storedRole?.name);
            if (role) this.selectRole(role);
        }
        
        if (!this.selectedRole) {
            this.selectRole(this.roles[0]);
            localStorage.setItem('role', JSON.stringify(this.selectedRole));
        }
    }

    selectRole(role: any): void {
        role.selected = true;
        this.selectedRole = role;
    }

    removeRole() {
        this.roles = [];
        localStorage.removeItem('role');
    }

    createCurrentDevicePlatform() {
        for (let device in this.currentProjectPlatforms) {
            if (device === 'Smartphone') {
                this.currentDevicePlatforms['mobile'] = this.currentProjectPlatforms[device];
                let selectedPlatform = this.currentDevicePlatforms['mobile'].filter(e => e.name == 'mobile site')[0];
                if (selectedPlatform) selectedPlatform.name = 'mobile-site';
            }
            else if (device === 'Desktop')
                this.currentDevicePlatforms['web'] = this.currentProjectPlatforms[device];
            else if(device === 'Watch')
                this.currentDevicePlatforms['watch'] = this.currentProjectPlatforms[device];
            else
                this.currentDevicePlatforms[device] = this.currentProjectPlatforms[device];
        }
    }

     swapDesigns(item, platform) { 
        let payload = {
            "build_card_id": this.currentProject.build_card_id,
            "platform1": this.selectedPlatformForTracker,
            "device1": this.currentProject.device,
            "platform2": platform,
            "device2": this.currentProject.device,
            "role_id": this.getRoleStatus()
        };
        this.uiwDataService.platformSwappApi(item.id, payload).subscribe(res => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    }

    getBaseSketch(mainFeature) {
        let imageUrl: string = '';
        if (this.designType === 'Sketch') imageUrl = mainFeature.base_sketch.image;
        else if (this.designType === 'Figma') imageUrl = mainFeature.base_sketch.figma_image_url;
        return imageUrl;
    }

    checkForBase(feature){
      if (this.designType === 'Sketch' && feature && feature.base_sketch && feature.base_sketch.file) return true;
      else if (this.designType === 'Figma' && ((feature && feature?.story && feature?.story?.ordered_documents.length === 0 && feature?.story?.figma_info && feature?.story?.figma_info?.node_id) || (feature?.story?.ordered_documents.length > 0))) return true;
      return false;
    }

    setMappingValue(value){
      this.mappingStory.next(value);
    }

    enableHotspotPage(mainFeature) {
        if(mainFeature && (!mainFeature.story || (mainFeature.story && mainFeature.story.ordered_documents && mainFeature.story.ordered_documents.length === 0))) return false;
        else return true;
    }

    disableLeftSubToolbarForDevs() {
        if (this.authService.isDeveloperRole()) {
            let ctas: any = document.querySelectorAll('.leftToolbar .selectPointer, .leftToolbar .commentSelector, .leftToolbar .copyAllScreen');
            for (let el of ctas) {
                el.classList.add('commDisableBtn');
            }
        }
    }

    downloadFile(fileUrl) {
        window.open(fileUrl, '_blank');
    }

    configureDesignType(designType: string, figmaFileKey: string) {
        this.designType = designType.toLowerCase() === 'figma' ? 'Figma' : 'Sketch';
        this.designTypeIconPath = designType.toLowerCase() === 'figma' ? 'upload_active_icon.svg' : 'upload_reupload_icon.svg';
        this.figmaBoardUrl = this.getFigmaBoardUrl(figmaFileKey);
    }

    isFigma() {
        return this.designType === 'Figma';
    }

    isStoryLoaded(feature) {
        return feature && feature.story && feature.story.ordered_documents && feature.story.ordered_documents.length > 0;
    }

    getPrimaryAttachment(feature) {
        if (this.isStoryLoaded(feature) && feature.build_card_hero_images && feature.build_card_hero_images.length > 1) {
            return feature.build_card_hero_images.find(doc => doc.is_primary);
        }
        return null;
    }

    getFigmaBoardUrl(fileKey: string, nodeId?: string) {
        let nodeQueryParam: string = '';
        if (nodeId) nodeQueryParam = `?node-id=${nodeId}`;
        return environment.FIGMA_URL + Constants.apiEndPoints.figmaBoardUrl.replace('{file_key}', fileKey) + nodeQueryParam;
    }

    openCloseStorySummaryPopup(event, story?) {
        event.stopPropagation();
        this.isToggleStorySummaryPopup = !this.isToggleStorySummaryPopup;
        if (this.isToggleStorySummaryPopup) {
            this.storySummaryData = story;
        }
    }

    navigateToStoryDetailPage(subtask, feature) {
        if (this.isIpmExist()) {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/ipm`], { queryParams: { story: subtask.id } });
        }
        else if (subtask.wireframe) {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${feature.studio_feature_id}`], { queryParams: { story: subtask.id, platform: this.selectedPlatformForTracker } });
        }
        else {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${feature.studio_feature_id}`], { queryParams: { story: subtask.id } });
        }
    }
    getAcceptedPrimarySketchUrl(orderedDocs) {
        let primaryImgDoc, sketchDoc;
        if (orderedDocs && orderedDocs.length > 0) {
            primaryImgDoc = orderedDocs.find(doc => doc.is_primary);
            if (primaryImgDoc) {
                sketchDoc = orderedDocs.find(doc => doc.content_type == 'application/octet-stream' && doc.id === primaryImgDoc?.sketch_document_id && doc.sketch_status === "sketch_accepted");
            }
        }
        return sketchDoc ? sketchDoc?.remote_url : false;
    }
    
    randomNumberGenerator() {
        let array = new Uint32Array(1);
        crypto.getRandomValues(array);
        return array[0] * Math.pow(2, -32);
    }

    blobCreationFromURL(inputURI, artBoardName) {
        let arr = inputURI.split(','),
            mime = inputURI.split(',')[0].split(':')[1].split(';')[0],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], `${artBoardName}.png`, {
            type: mime
        });
    }
    
    getAllFeature(): Observable<any> {
        return this.allFeature.asObservable();
    }

    setAllFeature(features: any) {
        this.allFeature.next(features);
    }

    setStoryForFilters(allStory){
            if (allStory.length > 0) {
                this.allStoryForFilter= [];
                allStory.forEach(element => {
                    let obj = {
                        id: element.id,
                        name: element.title,
                        builder_feature_id: element.builder_feature_id,
                        type: 'stories',
                        design_status: element.design_status,
                        owned_by_current_user: element.owned_by_current_user,
                        child_stories: element.child_stories
                    }
                    this.allStoryForFilter.push(obj);
                });
                const obj = {
                    name: 'All',
                    type: 'stories',
                    checked: false
                }
                this.allStoryForFilter.splice(0, 0, obj);
            }
    }

    setDefaultPlatform(all_platforms : Array<any>) :void{
        let platform : Object | undefined = all_platforms?.find(x => x.name === 'ios' || x.name === 'web');
        this.selectedPlatformForTracker = platform ? platform['name'] : all_platforms[0]?.name;
    }
    
    setSprints(sprints){
        this.sprints = sprints;
    }

    gotoInfoPage(object, event, projectId) {
        event.stopPropagation();
        if (this.storyType !== 'me' || (this.storyType === 'me' && (this.authService.getTrackerLoggedInUser()?.user?.name === object?.designer_name))) {
            if (!this.activateOverlay) {
                this.attachmentsArray = [];
                this.isComponentLoded = true;
                if (object.is_ipm && !object.builder_feature_id) {
                    this.router.navigate([`uiworkspace/${projectId}/featureDetail/ipm`], { queryParams: { story: object.id } });
                }
                else if (object && object.wireframe) {
                    this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${object.builder_feature_id}`], { queryParams: { story: object.id, platform: this.selectedPlatformForTracker } });
                }
                else {
                    this.router.navigate([`uiworkspace/${projectId}/featureDetail/${object.builder_feature_id}`], { queryParams: { story: object.id }, state: { subStory: true } });
                }
            }
        } else {
            return false;
        }
    }

    /**
     * This fn is using for get current sprints
     */
    getRunningSprints() {
        const allCurrentSprintIds = Object.values(this.sprints).filter(arrObj => arrObj.status === 'running').map(function (e) { return e.id });
        return allCurrentSprintIds;
    }

    getMappedKey(platformName:string) : string{
        return platformName === 'Smartphone' ? 'mobile' : (platformName === 'Desktop' ? 'web' : platformName);
    }

    /**
     * Get the pre-calculated image dimensions based on the story status i.e base sketch/figma or heroImage
     * @param {any} feature - Feature
     * @returns {dimensions_updated: boolean, height: number, width: number}
     */
    allDimensionMethod(feature: any): {
        dimensions_updated: boolean,
        height: number,
        width: number
    } {
        let dimensionObj: any = {
            height: 0,
            width: 0,
            dimensions_updated: false
        };
            dimensionObj = feature?.build_card_hero_images?.filter(heroImg => heroImg.is_primary)[0]?.hero_image_attributes;
        return {
            height: +dimensionObj?.height,
            width: +dimensionObj?.width,
            dimensions_updated: dimensionObj?.dimensions_updated
        };
    }

    default(){
    // This is for test case handling.
    }

    checkPromoteFigmaBaseCnd(feature):Boolean{
        return feature && feature?.story && feature?.story?.cmt_id && feature?.base_sketch?.figma_file_url;
     }


     stripTags(html: string): string {
        if (!html) {
            return '';
        }
        return html.replace(/<[^>]*>/g, '');
      }
    
    
      calculateWordDiff(original: string, modified: string) {
        if ((original === null && modified === null)) {
          return { originalHtml: '', modifiedHtml: '' };
        }
      
        const originalWords = original ? this.stripTags(original).split(' ') : [];
        const modifiedWords = modified ? this.stripTags(modified).split(' ') : [];
      
        const dp: number[][] = [];
      
        for (let i = 0; i <= originalWords.length; i++) {
          dp[i] = [];
          for (let j = 0; j <= modifiedWords.length; j++) {
            dp[i][j] = 0;
          }
        }
        
        for (let i = 1; i <= originalWords.length; i++) {
          for (let j = 1; j <= modifiedWords.length; j++) {
            if (originalWords[i - 1] === modifiedWords[j - 1]) {
              dp[i][j] = dp[i - 1][j - 1] + 1;
            } else {
              dp[i][j] = Math.max(dp[i][j - 1], dp[i - 1][j]);
            }
          }
        }
      
        let i = originalWords.length;
        let j = modifiedWords.length;
        let originalHtml = '';
        let modifiedHtml = '';
        let addedText = '';
        let deletedText = '';
        let additions = 0;
        let deletions = 0;
    
        while (i > 0 || j > 0) {
          if (i > 0 && j > 0 && originalWords[i - 1] === modifiedWords[j - 1]) {
            if (addedText !== '') {
              modifiedHtml = '<span class="diffAdded">' + addedText + '</span> ' + modifiedHtml;
              additions++;
              addedText = '';
            }
            if (deletedText !== '') {
              originalHtml = '<span class="diffRemoved">' + deletedText + '</span> ' + originalHtml;
              deletions++;
              deletedText = '';
            }
            originalHtml = originalWords[i - 1] + ' ' + originalHtml;
            modifiedHtml = modifiedWords[j - 1] + ' ' + modifiedHtml;
            i--;
            j--;
          } else if (j > 0 && (i === 0 || dp[i][j - 1] >= dp[i - 1][j])) {
            addedText = modifiedWords[j - 1] + ' ' + addedText;
            j--;
          } else {
            deletedText = originalWords[i - 1] + ' ' + deletedText;
            i--;
          }
        }
      
        if (addedText !== '') {
          modifiedHtml = '<span class="diffAdded">' + addedText + '</span> ' + modifiedHtml;
          additions++;
        }
        if (deletedText !== '') {
          originalHtml = '<span class="diffRemoved">' + deletedText + '</span> ' + originalHtml;
          deletions++;
        }
      
        return {
          originalHtml: original === '' ? '' : originalHtml.trim(),
          modifiedHtml: modified === '' ? '' : modifiedHtml.trim(),
          additions: additions ? additions : 0,
          deletions: deletions ? deletions : 0
        };
      }


      onPlayPauseSound(feature) {
        const { story: { current_timer_activity } } = feature;
        const audio = new Audio();
        const sourceUrl = current_timer_activity ? '../../../../assets/uiw-assets/mouse-click-pause.mp3' : '../../../../assets/uiw-assets/mouse-click-play.mp3';
        
        audio.src = sourceUrl;
        audio.addEventListener('canplaythrough', () => {
            audio.play();
        });
    }
    
    isOpenDlsUsagePopup() {
        if (this.currentDlsUsage?.build_card && Object.keys(this.currentDlsUsage.build_card).length) this.openDlsUsagePopup = !this.openDlsUsagePopup;
    }
    
    /**
     * Function to get the file type of the attachment
     * @param {any} attachment - Attachment
     * @returns {string}
     */
    getFileType(attachment: any): string {
        let arr: any[] = attachment?.name?.split('.'),
            type: string
        if (arr?.length) type = arr[arr?.length - 1];
        return type;
    }

    /**
     * Function to get the icon type of the attachment based on the file type
     * @param {any} attachment - Attachment
     * @returns {string}
     */
    getIconType(attachment: any): string {
        switch (attachment?.type) {
            case 'sketch':
                return 'figmasketchicon';
            case 'doc':
                return 'documenticon';
            case 'zip':
            case 'rar':
                return 'zipicon';
            case 'jpg':
            case 'png':
                return 'imageicon';
            default:
                return 'documenticon';
        }
    }

    setTimerActivity(data) {
        this.currentActiveStory.id = data?.id;
        this.currentActiveStory.builder_feature_id = data?.builder_feature_id;
        this.currentActiveStory.title = data?.title;
        this.currentActiveStory.owner_email = data?.owner_email;
        this.currentActiveStory.estimate = data?.estimate;
        this.currentActiveStory.timer_elapsed_total = data?.timer_elapsed_total;
        this.currentActiveStorySubject.next();
    }

    isMatchStoryOwner() {
        const userDetails = this.authService.getTrackerLoggedInUser()?.user;
        if (userDetails?.email === this.currentActiveStory.owner_email) {
            return true;
        } else {
            return false;
        }
    }
 
    createHotSpotMapping(bchiObject: any): void {
        bchiObject.forEach(element => {
            element.hotspots.forEach(hotspot => {
                hotspot?.clickable_items?.forEach(clickable => {
                    if (clickable.target_bchi_id) clickable.target_feature_uniq_code = clickable.target_feature_uniq_code + clickable.target_bchi_id;
                });
            });
        });
    }

    checkForSubFeature(feature: any, isFlowchart: boolean): PreviewModel {
        let subArr = [];
        if (feature?.sub_features?.length > 0) {
            let subFeatures = JSON.parse(JSON.stringify(feature.sub_features));
            for (let i = 0; i < subFeatures.length; i++) {
                let inner = subFeatures[i].build_card_hero_images_group;
                let clonedUniqueCode = JSON.parse(JSON.stringify(subFeatures[i].uniq_code));
                let subFeatureTitle = subFeatures[i].title;
                let titleCount = -1;
                for (const property in inner) {
                    titleCount++;
                    subFeatures[i].build_card_hero_images = inner[property];
                    if (isFlowchart) this.createHotSpotMapping(inner[property]);
                    let is_Primary = inner[property].find(e => e.is_primary);
                    subFeatures[i].artBoardName = is_Primary?.artboard_name ? is_Primary?.artboard_name :subFeatureTitle;
                    subFeatures[i].uniq_code = clonedUniqueCode + is_Primary?.build_card_hero_image_id;
                    subFeatures[i].nowStatus =  is_Primary?.status;
                    subFeatures[i].is_main = is_Primary?.is_main;
                    subFeatures[i].title = titleCount === 0 ? subFeatureTitle : `${subFeatureTitle} ${titleCount}`;
                    let build_card_hero_image_id = is_Primary?.build_card_hero_image_id;
                    subFeatures[i].bchidForPrimaryOfGroup = build_card_hero_image_id;
                    // subFeatures[i].id = build_card_hero_image_id;
                    subArr.push(JSON.parse(JSON.stringify(subFeatures[i])));
                }
            }
            feature.sub_features = subArr;
            return feature;
        }
        else return feature
    }

    private addDlsMetric(clonedFeature) {
        clonedFeature.dlsMetrics = {};
        if (clonedFeature.story) {
            const document = this.getSketchDocument(clonedFeature.story, clonedFeature.bchidForPrimaryOfGroup);
            if (document) {
                clonedFeature.dlsMetrics = document.dls_metrics;
            }
        }

    }

    flatBuildCard(features: PreviewModel[], isFlowchart: boolean, mapSubFeature :boolean ,mapStory :boolean ,collectError :boolean): PreviewModel[] {
        console.time("Time this");
        let newFeatures = JSON.parse(JSON.stringify(features));
        let arr = [];
        for (let i = 0; i < newFeatures.length; i++) {
            let clonedFeature = JSON.parse(JSON.stringify(newFeatures[i]));
            if(clonedFeature.isVirtualFeature){         
                clonedFeature.sketch_status ='sketch_awaited';      
                clonedFeature.subStoryTitleToBeDisplayed = clonedFeature.story.title;
                clonedFeature.newTitle = clonedFeature.story.title;
                if(collectError) this.manageErrorsOnPrimaryScreen(clonedFeature);
                arr.push(JSON.parse(JSON.stringify(clonedFeature)));
                continue;
            }
            let inner = clonedFeature.build_card_hero_images_group;
            let clonedUniqueCode = clonedFeature.uniq_code;
            let mapppingForSubFeature = -1;
            let titleCount = -1;
            let featureTitle = clonedFeature.title;
            for (const property in inner) {
                mapppingForSubFeature++;
                titleCount++;
                clonedFeature.build_card_hero_images = inner[property];
                if (isFlowchart) this.createHotSpotMapping(inner[property]);
                let is_Primary = inner[property].find(e => e.is_primary);
                clonedFeature.artBoardName = is_Primary?.artboard_name ? is_Primary?.artboard_name :featureTitle;
                clonedFeature.uniq_code = clonedUniqueCode + is_Primary?.build_card_hero_image_id;
                clonedFeature.nowStatus =  is_Primary?.status;
                clonedFeature.is_main = is_Primary?.is_main;
                let build_card_hero_image_id = is_Primary?.build_card_hero_image_id;
                clonedFeature.bchidForPrimaryOfGroup = build_card_hero_image_id;
                clonedFeature.figmaScreenName = is_Primary?.figma_screen_name;
                // clonedFeature.id = build_card_hero_image_id;
                this.addDlsMetric(clonedFeature);
                if(clonedFeature.story?.ordered_documents?.length > 0) {
                    clonedFeature.node_Id =  clonedFeature.story?.ordered_documents?.find(doc=> doc.hero_image_feature_id == build_card_hero_image_id)?.node_id;
                }
                else{
                    clonedFeature.node_Id =  clonedFeature.story?.figma_info?.node_id;
                }
                clonedFeature.versionFromGroup = is_Primary?.version;
                if(mapStory){
                    let mappedDocument = this.getSketch_FigmaInfo(clonedFeature.story,clonedFeature);
                    clonedFeature.sketch_status = mappedDocument?.sketch_status;
                    clonedFeature.figmaMismatch = mappedDocument?.figma_mismatch;
                    clonedFeature.figmaVersionName = mappedDocument?.figma_version_name;
                    clonedFeature.newTitle = mappedDocument?.primaryTitle;
                    clonedFeature.scopeValidation = mappedDocument?.scopeValidationFromError;
                }
                else{
                    clonedFeature.newTitle = featureTitle;
                 clonedFeature.sketch_status = null;
                }
                if(collectError) this.manageErrorsOnPrimaryScreen(clonedFeature);
                if(mapppingForSubFeature == 0 && mapSubFeature) arr.push(JSON.parse(JSON.stringify(this.checkForSubFeature(clonedFeature, isFlowchart))));
                else arr.push(JSON.parse(JSON.stringify(clonedFeature)));
            }
        }
        console.timeEnd("Time this");
        console.log(this.errorDetails);
        console.log(arr);
        return arr;
    }

    flatBuildCardForSubStory(subStories: PreviewModel[]): PreviewModel[] {
        this.subStoryCount = 0;
        console.time("Time subtory"); 
        let newSubStories = JSON.parse(JSON.stringify(subStories));
        let arr = [];
        let multiArray =[];
        for (let i = 0; i < newSubStories.length; i++) {
            let clonedSubStory = JSON.parse(JSON.stringify(newSubStories[i]));
        if(clonedSubStory.isVirtualFeature){
            this.subStoryCount++;
            clonedSubStory.sketch_status ='sketch_awaited'; 
            clonedSubStory.subStoryTitleToBeDisplayed = clonedSubStory.story.title;
            clonedSubStory.newTitle = clonedSubStory.story.title;
            arr.push(JSON.parse(JSON.stringify(clonedSubStory)));
        }
        else{
            let bchiGroup = clonedSubStory.build_card_hero_images_group;
            let clonedUniqueCode = clonedSubStory.uniq_code;
            let mapppingForTitle = -1;
            let titleCount = -1;
            for (const property in bchiGroup) {
                mapppingForTitle++;
                titleCount++;
                if(clonedSubStory.subStoryTitleToBeDisplayed) clonedSubStory.subStoryTitleToBeDisplayed = null
                clonedSubStory.build_card_hero_images = bchiGroup[property];
                this.createHotSpotMapping(bchiGroup[property]);
                let is_Primary = bchiGroup[property].find(e => e.is_primary);
                clonedSubStory.uniq_code = clonedUniqueCode + is_Primary?.build_card_hero_image_id;
                clonedSubStory.nowStatus =  is_Primary?.status;
                clonedSubStory.is_main = is_Primary?.is_main;
                let build_card_hero_image_id = is_Primary?.build_card_hero_image_id;
                clonedSubStory.bchidForPrimaryOfGroup = build_card_hero_image_id;
                if(clonedSubStory.story?.ordered_documents?.length > 0){
                    clonedSubStory.node_Id = clonedSubStory.story?.ordered_documents?.find(doc=> doc.hero_image_feature_id == build_card_hero_image_id)?.node_id;
                }else{
                    clonedSubStory.node_Id = clonedSubStory.story?.figma_info?.node_id;
                }
                clonedSubStory.versionFromGroup = is_Primary.version;
                if (mapppingForTitle == 0) {
                    clonedSubStory.subStoryTitleToBeDisplayed = clonedSubStory.story.title
                    this.subStoryCount++;
                };
                let mappedDocument = this.getSketch_FigmaInfo(clonedSubStory.story,clonedSubStory);
                clonedSubStory.newTitle = mappedDocument?.primaryTitle;
                clonedSubStory.sketch_status = mappedDocument?.sketch_status;
                arr.push(JSON.parse(JSON.stringify(clonedSubStory)));
             }
            }
            let size = this.selectedPlatformForTracker == 'web' ? 5: 10;
            if(arr.length > size){
                const chunkSize = size;
                for (let i = 0; i < arr.length; i += chunkSize) {
                    const chunk = arr.slice(i, i + chunkSize);
                    multiArray.push(chunk);
                }
                arr =[];
                
            }
            else if(arr.length < size && i == 0){
                multiArray.push(arr);
                arr= [];
            }     
            else if(arr.length  < size && i !== 0){
                let lastIndexOfMultiArray = multiArray.length - 1;
                let calculateBuffer = multiArray[lastIndexOfMultiArray].length + arr.length;
                if(calculateBuffer > size){
                    multiArray.push(arr);
                    arr =[];
                }else{
                   let lastIndexArray = multiArray[lastIndexOfMultiArray];
                   multiArray[lastIndexOfMultiArray] = lastIndexArray.concat(arr);
                   arr =[];
                }
            }
        }
        console.timeEnd("Time subtory");
        return multiArray;
    }
    getSketch_FigmaInfo(story, clonedFeature) {
        if (story && story.ordered_documents && story.ordered_documents.length > 0) {
            let primaryDocument = this.getDocument(story, clonedFeature.bchidForPrimaryOfGroup);
            if(primaryDocument){
                let doc = primaryDocument?.document;
                let allDocs = primaryDocument?.allDocuments;
                if (doc && doc.sketch_document_id) {
                    let sketch = allDocs.find(e => e.id == doc.sketch_document_id);
                    if (sketch) {
                         return { sketch_status : sketch.sketch_status ,figma_mismatch:sketch.figma_mismatch ,figma_version_name:sketch.figma_version_name , node_id: sketch.node_id, primaryTitle : (clonedFeature.figmaScreenName ? clonedFeature.figmaScreenName : sketch.artboard_name),scopeValidationFromError : sketch.sketch_error?.scope};
                     }
                }
            }
        }
         return { sketch_status:'sketch_awaited' ,primaryTitle : (clonedFeature.figmaScreenName ? clonedFeature.figmaScreenName : story.title)};
     }

    getNonPrimaryVariantsCountForBchiGroup(flatFeature) {
        return flatFeature && flatFeature.build_card_hero_images.filter(img => !img.is_primary).length;
    }

    getNodeIdCount(feature) {
        if (feature && feature.story) {
            let primaryDocument = this.getDocument(feature.story, feature.bchidForPrimaryOfGroup);
            if (primaryDocument) {
                let doc = primaryDocument.document;
                let allDocs = primaryDocument.allDocuments;
                if (doc && doc.sketch_document_id) {
                    let sketch = allDocs.find(e => e.id == doc.sketch_document_id);
                    if (sketch) {
                        return sketch.same_node_story_count;
                    }
                }
            }
            return null;
        }
    }

    manageErrorsOnPrimaryScreen(clonedFeature){
        let errors =[];

        // UIE Validation Start //
        if(clonedFeature.sketch_status !== 'sketch_accepted' && clonedFeature.sketch_status !== 'sketch_awaited'){
            let errorObject ={
                errorType : 'sketch_rejected',
                errorCode : 0,
                errorDetails: "Design didn't pass UI Engine validation"
            }
            errors.push(errorObject);
        }
        // UIE Validation End //


        // UnMapped validaiton Start //
        let mapNavedCount = 0;
        let counts = this.calculateHotspotCountDelinking(clonedFeature);
        mapNavedCount = counts.editedCount;
        let navHotspotCount = counts.navHotspotCount;
          if (((this.dataCommService.navHotspotsRequired && navHotspotCount) ||!this.dataCommService.navHotspotsRequired) && mapNavedCount === 0){
            let errorObject ={
                errorType : 'unMappedCount',
                errorCode : 1,
                errorDetails : `One of the hotspots should be mapped to another screen.`
            }
            errors.push(errorObject);
        }  
        // UnMapped validaiton End //

        // targetFeaturesWithoutPrimarySketch validaiton Start //
        let targetFeatureWithoutPrimary = this.getTargetFeaturesWithoutPrimarySketch(clonedFeature);
        if(this.dataCommService.navHotspotsRequired && navHotspotCount && targetFeatureWithoutPrimary.isPresent) {
            let errorObject ={
                errorType : 'targetFeaturesWithoutPrimarySketch',
                errorCode : 2,
                errorDetails : `You need to upload ${this.designType} files on the mentioned features: ${targetFeatureWithoutPrimary.features}`
            }
            errors.push(errorObject);
        }
        // targetFeaturesWithoutPrimarySketch validaiton End //


        // navHotspotsRequired validaiton Start //
        if(this.dataCommService.navHotspotsRequired && !counts.navHotspotCount){
            let errorObject ={
                errorType : 'noNavigationalHotSpotCount',
                errorCode : 3,
                errorDetails : `No navigational hotspot was found in the design.`
            }
            errors.push(errorObject);
        }
         // navHotspotsRequired validaiton End //

           // navHotspotsRequired validaiton Start //
        if(clonedFeature.nowStatus == 'rejected'){
            let errorObject ={
                errorType : 'nowStatusRejected',
                errorCode : 4,
                errorDetails : `This design screen is rejected Please upload a new design.`
            }
            errors.push(errorObject);
        }
         // navHotspotsRequired validaiton End //

        if(errors.length > 0) {
            let finalErrorObject ={
                bchidForPrimaryOfGroup : clonedFeature.bchidForPrimaryOfGroup,
                primaryTitle: clonedFeature.newTitle,
                errors :errors
            }
            this.errorDetails.push(finalErrorObject);
        }
    }

    getTargetFeaturesWithoutPrimarySketch(feature) {
        let result = [],
          primaryBuildCardHeroImg = feature?.build_card_hero_images?.filter(e => e.is_primary)[0],
          hotspotsWithoutPrimarySketch = primaryBuildCardHeroImg?.hotspots?.filter(e => e.category_id === 4 && !e.target_artboard_name);
        hotspotsWithoutPrimarySketch?.forEach(hotspot => {
          hotspot?.clickable_items?.forEach(clickableItem => {
            if (clickableItem?.target_feature_uniq_code && !clickableItem?.valid_hotspot_mapping && !result.includes(clickableItem?.feature_title))
              result.push(clickableItem?.feature_title);
          });
        });
        return {
          features: result.join(','),
          isPresent: result.length ? true : false
        };
    }
    calculateHotspotCount(feature) {
        const primaryTrueFeature = this.filterFeature(feature);
        let obj: any = {};
        if (this.router.url.indexOf('edithotspot') === -1) {
          let count = 0;
          let editedCount = 0;
          let navHotspotCount = 0;
          if (primaryTrueFeature && primaryTrueFeature?.hotspots?.length > 0) {
            primaryTrueFeature.hotspots.forEach(hotspot => {
              if (hotspot.category_id !== 5 && hotspot.category_id !== 6) {
                if (hotspot.sketch_generated && (!hotspot.clickable_items?.length || !this.dataCommService.navHotspotsRequired)) {
                  count++;
                  if (hotspot.position.is_edited == 'true') editedCount++;
                }
                else
                  hotspot.clickable_items.forEach(item => {
                    if (hotspot?.sketch_generated || this.dataCommService.getAllFeatures().find(el => el.uniq_code === item.target_feature_uniq_code)) {
                      count++;
                      if (hotspot?.position?.is_edited === 'true') editedCount++;
                    }
                  });
              }
              if (hotspot.category_id === 4) navHotspotCount++;
            });
            obj = {count: count, editedCount: editedCount, navHotspotCount: navHotspotCount};
          } else {
            obj = {count: 0, editedCount: 0};
          }
        } else {
          let editedCount = 0;
          let totalCount = 0;
          const rectangles = this.rectangleService.getRectangleComponents().filter(e => this.dataCommService.getAllFeatures().find(el => el.uniq_code === e.target_feature_uniq_code));
          rectangles.forEach(rect => {
            if (rect.id) {
              totalCount++;
            }
            if (rect.isSaved && rect.id) {
              editedCount++;
            }
          });
    
          obj = {count: totalCount, editedCount: editedCount};
        }
        if (feature && feature.story) {
          this.canStoryBeFinished[feature.story.id] = obj.count === obj.editedCount;
        }
        return obj;
    }

    getDlsUsageData(projectId, storyId?: string) {
        this.uiwDataService.getDlsUsageApiCall(projectId, storyId).subscribe(
            (data: any) => {
                this.currentDlsUsage = data;
            },
            error => {
                console.log(error);
            }
        );
    }

    toggleFigmaMismatchPopup(event) {
        event.stopPropagation();
        this.figmaMismatchObject['showPopup'] = !this.figmaMismatchObject['showPopup'];
    }
    resetFigmaInfo(){
        this.figmaMismatchObject ={
          figma_validation:null,
          showPopup:false,
          stepNumber : 0,
          misMatchData:{}
        }
        this.story_Id = null;
      }

      finishStoryApiCall(featureData,isIpm,isWireFrame){
        this.uiwDataService
                .setStoryStatus(featureData, 'delivered', this.currentProject.id,isIpm,isWireFrame)
                .subscribe(
                    (data: any) => {
                    },
                    error => {
                      console.log(error);
                    }
                );
      }
      getSketchUrl(feature): string | undefined {
        const primaryDocument = feature?.story?.ordered_documents.find(e => e.hero_image_feature_id === feature.bchidForPrimaryOfGroup && e.image_document_id);
        return primaryDocument?.remote_url || feature?.story?.client_sketch_url;
      }      
      calculateHotspotCountDelinking(feature){
        const primaryTrueFeature = this.filterFeature(feature);
        let obj: any = {};
          let editedCount = 0;
          let navHotspotCount = 0;
          if (primaryTrueFeature && primaryTrueFeature?.hotspots?.length > 0) {
            primaryTrueFeature.hotspots.forEach(hotspot => {
              if (hotspot.category_id === 4) {
                  navHotspotCount++;
                   hotspot.clickable_items.forEach(item => {
                    if (item.target_bchi_id && item.target_bchi_id !== feature.bchidForPrimaryOfGroup ) {
                        editedCount++;
                    }
                  });
              }
            });
            obj = {editedCount: editedCount, navHotspotCount: navHotspotCount};
          } else {
            obj = { editedCount: 0,navHotspotCount: navHotspotCount};
          }
        
        if (feature && feature.story) {
          this.canStoryBeFinished[feature.story.id] = obj.count === obj.editedCount;
        }
        return obj;
    }
        public isStoryDetailPage(): boolean {
        return this.router.url.indexOf('featureDetail') > -1;
      }

    public getBchatData(builder360Id,userEmail) {
        this.uiwDataService.getBchatDataApi(builder360Id,userEmail).subscribe((res: any) => {
            if(res.type == 'builderchat'){
              this.bchatDataObject = res;
              this.bchatSettings.channelId = res?.channel_pid;
              this.bchatSettings.workspaceId = res?.workspace_pid;
              this.bchatSettings.senderId = res?.user_pid;
            }
        },err=>{
            this.bchatDataObject = null;
        });
    }
    
    openFigmaAccessPopup(event) {
        event.stopPropagation
        this.isOpenFigmaAccessPopup = !this.isOpenFigmaAccessPopup;
    }

    toggleSendCustomerPopup(event) {
        if(!this.isOpenSendCustomerPopup && this.finishedStoryCount === 0)  return;
        event?.stopPropagation();
        this.isOpenSendCustomerPopup = !this.isOpenSendCustomerPopup;
    }

    deepClone(dataObject){
        return JSON.parse(JSON.stringify(dataObject));
    }

    getTabName(projectName){
        return projectName ? `UIW - ${projectName}` : 'UIW';
    }

    getUser(){
       return this.authService?.userProfile?.trackerUserData?.user;
    }

    resolveApiCommonFunc(event, projectId , note ,callSubject){
        event?.stopPropagation();
        this.uiwDataService.resolvedByUser(projectId, note.id)
            .subscribe(
                (data: any) => {
                    if(callSubject){
                        let object ={
                            customType : 'resolve',
                            is_resolved: data.is_resolved,
                            id: note.id
                        }
                    this.singleCommentSelectSubject.next(object);
                    }
                    this.updateRightPanelCommonFunc(note,data.is_resolved);
                    note.is_resolved = data.is_resolved;
                },
                error => {
                    console.log(error);
                }
            );
    }

    updateRightPanelCommonFunc(note,value){
        this.commentsNumberData.forEach(comment=>{
          if(comment.note.id === note.id){
            comment.note.is_resolved =  value;
          }
        });
      
        this.clonedCommentsRight.forEach(comment=>{
          if(comment.note.id === note.id){
            comment.note.is_resolved =  value;
          }
        });
    }
    
    capitalizeFirstLetter(string) {
        if(string == 'ios') return 'iOS';
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
      }

      openJourneyNotes(event) {
        event?.stopPropagation
        this.isOpenJourneyDetailPanel = !this.isOpenJourneyDetailPanel;
    }

    createPlatformArray(platforms){
        this.platformsArr = [];
        for (const property in platforms) {
            let platformArray :Array<any> = platforms[property];
            platformArray?.forEach(element => {
            //   let obj ={
            //     display: this.capitalizeFirstLetter(element.name),
            //     value:element.name
            //   }
              this.platformsArr.push(element.name);
            });
          }
    }

    //Journey Filters Methods//

    getAppliedFilters(appliedFilterArray) {
        return {
            platforms: appliedFilterArray?.filter(e => e?.type === "platforms"),
            role_ids: appliedFilterArray?.filter(e => e?.type === "role_ids"),
            sprint_ids: appliedFilterArray?.filter(e => e?.type === "sprint_ids"),
            story_Status: appliedFilterArray?.filter(e => e?.type === "story_Status")
        };
    }

    setSprint(sprints: any, filterObject) {
        sprints.forEach(sprint => {
            let obj = {
                display: sprint.name,
                value: sprint.id,
                status: sprint.status,
                type: 'sprint_ids'
            };
            filterObject['sprint_ids'].push(obj);
        });
    }

    setRoles(roles, filterObject) {
        roles.forEach(role => {
            let obj = {
                display: role.name.replace(/_/g, " ").toUpperCase(),
                value: role.studio_id,
                type: 'role_ids',
                id: role.id
            }
            filterObject['role_ids'].push(obj);
        });
    }

    setPlatforms(platforms, filterObject) {
        for (const property in platforms) {
            let platformArray: Array<any> = platforms[property];
            platformArray?.forEach(element => {
                let obj = {
                    display: this.capitalizeFirstLetter(element.name),
                    value: element.name,
                    type: 'platforms'
                }
                filterObject['platforms'].push(obj);
            });
        }
    }

    setStoryMappingJourney(filterObject) {
        let statusMapping = JSON.parse(JSON.stringify(journeyConstants.storyStautsMapping))
        filterObject.story_Status = statusMapping;
    }
    
    isIpm(stories) {
        if (stories && stories.is_ipm) {
          return 'Internal Project Managment'
        }
        else {
          return stories.title;
          }
    }

    flatJourneyStories(storiesMappedWithFeature,featureForMapping){
        let arr = [];
        let multiArray = [];
            let stories = storiesMappedWithFeature.stories;
            for (let j = 0; j < stories.length; j++) {
                if(!stories[j].wireframe && !stories[j].is_ipm && Object.keys(stories[j].build_card_hero_images_group).length > 0){
                let clonedFeature = JSON.parse(JSON.stringify(featureForMapping));
                let bchiGroup = stories[j]?.build_card_hero_images_group;
                let childStories = stories[j]?.child_stories;
                let currentIndex = -1;
                const totalProperties = Object.keys(bchiGroup).length;
                for (const property in bchiGroup) {
                    currentIndex++;
                    clonedFeature.build_card_hero_images = bchiGroup[property];
                     let is_Primary = bchiGroup[property].find(e => e.is_primary);
                     let build_card_hero_image_id = is_Primary?.build_card_hero_image_id;
                     clonedFeature.bchidForPrimaryOfGroup = build_card_hero_image_id;
                     clonedFeature.versionFromGroup = is_Primary?.version;
                     let mappedDocument = this.getSketch_FigmaInfo(stories[j],clonedFeature);
                     clonedFeature.sketch_status = mappedDocument?.sketch_status;
                     clonedFeature.story = stories[j];
                     clonedFeature.storyType = 'main';
                     clonedFeature.nowStatus =  is_Primary?.status;
                     clonedFeature.storyPlatform = stories[j]?.all_platforms[0]?.name;
                     clonedFeature.storyDevice = this.deviceMappedWithPlatform(clonedFeature.storyPlatform);
                     clonedFeature.studio_feature_id = stories[j].builder_feature_id;
                     clonedFeature.nowStatus =  is_Primary?.status;
                     clonedFeature.newTitle = mappedDocument?.primaryTitle;
                     if (currentIndex === 0) {
                        clonedFeature.isFirst = true;
                     }else{
                        clonedFeature.isFirst = false;
                     }

                    if (currentIndex === totalProperties - 1) {
                        clonedFeature.isLast = true;
                    }
                
                    if(mappedDocument?.node_id) {
                        clonedFeature.node_Id =  mappedDocument.node_id
                    }else{
                        clonedFeature.node_Id =  clonedFeature.story?.figma_info?.node_id;
                    }
                    arr.push(JSON.parse(JSON.stringify(clonedFeature)));
            }
            if(stories[j].design_status !== 'accepted' && stories[j].design_status !== 'delivered' && stories[j].design_status !== 'unscheduled' && stories[j].design_status !== 'rejected' && stories[j]?.ordered_documents?.length > 0 && this.isNotAssignedUser(stories[j])){
                let addPrimaryCard ={
                    build_card_hero_images : Object.values(bchiGroup)[0],
                    id: featureForMapping?.id,
                    previewStyleObj: { width : '403px' ,height: '840px'},
                    styleObj: { width : '403px' ,height: '909px'},
                    storyOpFrameStyleObj : { width : '403px' ,height: '840px'},
                    story : stories[j],
                    isAddPrimaryCard : true,
                    storyPlatform :stories[j]?.all_platforms[0]?.name,
                    isAddDesignLoaded : true,
                    studio_feature_id : stories[j].builder_feature_id,
                    storyDevice :this.deviceMappedWithPlatform(clonedFeature.storyPlatform)
                }
                arr.push(JSON.parse(JSON.stringify(addPrimaryCard)));
            }

            let size = 10;
            if(arr.length >= size){
                const chunkSize = size;
                for (let k = 0; k < arr.length; k += chunkSize) {
                    const chunk = arr.slice(k, k + chunkSize);
                    multiArray.push(chunk);
                }
                arr =[];
                
            }
            else if((arr.length < size && j == 0) || (arr.length < size && multiArray.length === 0)){
                multiArray.push(arr);
                arr= [];
            }     
            else if(arr.length  < size && j !== 0){
                let lastIndexOfMultiArray = multiArray.length - 1;
                let calculateBuffer = multiArray[lastIndexOfMultiArray].length + arr.length;
                if(calculateBuffer > size){
                    multiArray.push(arr);
                    arr =[];
                }else{
                   let lastIndexArray = multiArray[lastIndexOfMultiArray];
                   multiArray[lastIndexOfMultiArray] = lastIndexArray.concat(arr);
                   arr =[];
                }
            }
            let flatChildStories = [];
            childStories?.forEach(childStory => {
            if(!childStory.wireframe && !childStory.is_ipm ){  
                if (Object.keys(childStory.build_card_hero_images_group).length === 0){
                    let cloneForVirtual  = JSON.parse(JSON.stringify(clonedFeature));
                    cloneForVirtual.isVirtualFeature = true;
                    cloneForVirtual.story = childStory;
                    cloneForVirtual.sketch_status ='sketch_awaited'; 
                    cloneForVirtual.storyType = 'child';
                    cloneForVirtual.isFirst = true;
                    cloneForVirtual.isLast = true;
                    cloneForVirtual.randomId =  Math.random();
                    cloneForVirtual.storyPlatform = childStory?.all_platforms[0]?.name;
                    cloneForVirtual.storyDevice = this.deviceMappedWithPlatform(cloneForVirtual.storyPlatform);
                    cloneForVirtual.studio_feature_id = childStory.builder_feature_id;
                    cloneForVirtual.newTitle = childStory.title;
                    cloneForVirtual.nowStatus = false;
                    flatChildStories.push(cloneForVirtual);
                }else{
                    flatChildStories = [...flatChildStories, ...this.calculationForChildStory(childStory)];
                }
             
              if(flatChildStories.length >= size){
                const chunkSize = size;
                for (let k = 0; k < flatChildStories.length; k += chunkSize) {
                    const chunk = flatChildStories.slice(k, k + chunkSize);
                    multiArray.push(chunk);
                }
                flatChildStories =[];
                
            }     
            else if(flatChildStories.length  < size){
                let lastIndexOfMultiArray = multiArray.length - 1;
                let calculateBuffer = multiArray[lastIndexOfMultiArray].length + flatChildStories.length;
                if(calculateBuffer > size){
                    multiArray.push(flatChildStories);
                    flatChildStories =[];
                }else{
                   let lastIndexArray = multiArray[lastIndexOfMultiArray];
                   multiArray[lastIndexOfMultiArray] = lastIndexArray.concat(flatChildStories);
                   flatChildStories =[];
                }
            }
           }
            });
          }
        }
        return multiArray;
}

    calculationForChildStory(childStory){
        let arrOfChild = [];
        let featureForMapping = {
            id: childStory?.build_card_feature_id,
            isAddDesignLoaded :true,
            cmt_id : childStory.cmt_id
        }
        let clonedFeature = JSON.parse(JSON.stringify(featureForMapping));
        let bchiGroup = childStory?.build_card_hero_images_group;
        let currentIndex = -1;
        const totalProperties = Object.keys(bchiGroup).length;
        for (const property in bchiGroup) {
            currentIndex++;
            clonedFeature.build_card_hero_images = bchiGroup[property];
             let is_Primary = bchiGroup[property].find(e => e.is_primary);
             let build_card_hero_image_id = is_Primary?.build_card_hero_image_id;
             clonedFeature.bchidForPrimaryOfGroup = build_card_hero_image_id;
             clonedFeature.versionFromGroup = is_Primary?.version;
             let mappedDocument = this.getSketch_FigmaInfo(childStory,clonedFeature);
             clonedFeature.sketch_status = mappedDocument?.sketch_status;
             clonedFeature.story = childStory;
             clonedFeature.storyType = 'child';
             clonedFeature.nowStatus =  is_Primary?.status;
             clonedFeature.storyPlatform = childStory?.all_platforms[0]?.name;
             clonedFeature.studio_feature_id = childStory.builder_feature_id;
             clonedFeature.storyDevice = this.deviceMappedWithPlatform(clonedFeature.storyPlatform);
             clonedFeature.newTitle = mappedDocument?.primaryTitle;
             if (currentIndex === 0) {
                clonedFeature.isFirst = true;
             }else{
                clonedFeature.isFirst = false;
             }

            if (currentIndex === totalProperties - 1) {
                clonedFeature.isLast = true;
            }
             arrOfChild.push(JSON.parse(JSON.stringify(clonedFeature)));
       }

       if(childStory.design_status !== 'accepted' && childStory.design_status !== 'delivered' && childStory.design_status !== 'unscheduled' && childStory.design_status !== 'rejected' && childStory?.ordered_documents?.length > 0 && this.isNotAssignedUser(childStory)){
        let addPrimaryCard ={
            build_card_hero_images : Object.values(bchiGroup)[0],
            id: featureForMapping?.id,
            previewStyleObj: { width : '403px' ,height: '840px'},
            styleObj: { width : '403px' ,height: '909px'},
            storyOpFrameStyleObj : { width : '403px' ,height: '840px'},
            story : childStory,
            isAddPrimaryCard : true,
            storyPlatform : childStory?.all_platforms[0]?.name,
            isAddDesignLoaded : true,
            studio_feature_id : childStory.builder_feature_id,
            cmt_id : childStory.cmt_id,
            storyDevice :this.deviceMappedWithPlatform(clonedFeature.storyPlatform)
        }
        arrOfChild.push(JSON.parse(JSON.stringify(addPrimaryCard)));
       }
      return arrOfChild;
    }
    isJourneyDetailPage() {
        return this.router.url.includes('journeyDetail');
    }

    isNotAssignedUser(story: any): boolean {
        const isOwnedByCurrentUser = story?.owned_by_current_user;
        const isParentIdNull = story?.parent_id;
        const isFullBuild = this.is_full_build;
        const isPrototype = this.is_prototype;
        const isProfessional = this.is_profressional;
        const checkUser = isOwnedByCurrentUser || (!isParentIdNull && isFullBuild && !isPrototype && isProfessional);
        return checkUser;
    }
    clearJourneyFilters(){
        localStorage.removeItem('journeyFilters');
    }

    dueDateValidationCommonFun(featureData){
        if(!featureData?.story?.sprint_running || !featureData?.story?.dev_release_at){
           return true
        }
        return false;
      }

    dueDateSprintText(featureData) {
        if (!featureData?.story?.sprint && !featureData?.story?.dev_release_at) {
            return 'Sprint and Due date are missing';
        } else if (!featureData?.story?.sprint && featureData?.story?.dev_release_at) {
            return 'Sprint is missing';
        } else if (featureData?.story?.sprint && !featureData?.story?.sprint_running && featureData?.story?.dev_release_at) {
            return "Sprint isn't active.";
        } else if (featureData?.story?.sprint && !featureData?.story?.sprint_running && !featureData?.story?.dev_release_at) {
            return "Sprint isn't active and Due date is missing";
        } else if (featureData?.story?.sprint && featureData?.story?.sprint_running && !featureData?.story?.dev_release_at) {
            return 'Due date is missing';
        }
    }

    cloneJourneyStories(journeyStories){
        this.clonedJourneyStoriesRightPanel = JSON.parse(JSON.stringify(journeyStories));
    }

    getClonedMapping(){
        return JSON.parse(JSON.stringify(this.clonedRolePlatformMapping));
    }

    createPlatformRoleMapping(platforms ,roles){
        let allPlatform = [];
        for (const property in platforms) {
            let platformArray :Array<any> = platforms[property];
            platformArray?.forEach(element => {
                allPlatform.push(element.name);
            });
          }
          this.rolePlatformMapping = [];
          if(this.roles.length > 0){
            allPlatform?.forEach(platform => {
                roles?.forEach(role => {
                    let obj = {platformName :platform, roleName:role.name ,studioRoleId : role.studio_id}
                    this.rolePlatformMapping.push(obj);
                });
              });
          }else{
            this.createOnlyPlatformMapping(allPlatform);
          }
          this.clonedRolePlatformMapping = JSON.parse(JSON.stringify(this.rolePlatformMapping))
    }

    createOnlyPlatformMapping(allPlatform){
        allPlatform?.forEach(platform => {
                let obj = {platformName :platform}
                this.rolePlatformMapping.push(obj);
        });
    }

    createFilteredMappingOnFilterChange(appliedRoles, AppliedPlatforms){
        if (appliedRoles?.length > 0 && AppliedPlatforms?.length === 0) {
            this.createRoleMapping(appliedRoles);
        } else if (AppliedPlatforms?.length > 0 && appliedRoles?.length === 0) {
            this.createPlatformMapping(AppliedPlatforms);
        } else if (AppliedPlatforms?.length > 0 && appliedRoles?.length > 0) {
            this.createBothAppliedMapping(appliedRoles,AppliedPlatforms)
        }
    }

     createRoleMapping(appliedRoles){
        this.rolePlatformMapping = [];
        let clonedMappedArray = this.getClonedMapping();
        appliedRoles.forEach(role => {
            let mappedRole = clonedMappedArray.filter(r=> r.studioRoleId == role.value);
            if(mappedRole) this.rolePlatformMapping = [...this.rolePlatformMapping,...mappedRole]
        });
    }

     createPlatformMapping(AppliedPlatforms){
        this.rolePlatformMapping = [];
        let clonedMappedArray = this.getClonedMapping();
        AppliedPlatforms.forEach(platform => {
            let mappedPlatform = clonedMappedArray.filter(p=> p.platformName.toLowerCase() == platform.value.toLowerCase());
            if(mappedPlatform) this.rolePlatformMapping = [...this.rolePlatformMapping,...mappedPlatform]
        });
    }

    createBothAppliedMapping(appliedRoles,AppliedPlatforms){
        let data = [];
        let clonedMappedArray = this.getClonedMapping();
        clonedMappedArray?.forEach(mainArray => {
            appliedRoles?.forEach(role => {
                if(role?.value == mainArray?.studioRoleId) data.push(mainArray);
            });
        })
        clonedMappedArray = JSON.parse(JSON.stringify(data));
         data = [];
        clonedMappedArray?.forEach(mainArray => {
            AppliedPlatforms?.forEach(platform => {
                if(platform?.value?.toLowerCase() == mainArray?.platformName?.toLowerCase()) data.push(mainArray);
            });
        })
        this.rolePlatformMapping = JSON.parse(JSON.stringify(data));
    }

    filterStoriesBasedOnMapping(clonedStories){
        if (!clonedStories || !this.rolePlatformMapping.length) return;
        const { platformName, studioRoleId } = this.rolePlatformMapping.find(e => e.selected);
        const lowerPlatformName = platformName.toLowerCase();
         if(this.roles.length > 0){
           this.filterStoryAndChildByRolePlatform(clonedStories,lowerPlatformName,studioRoleId);
         }else{
            this.filterStoryAndChildByPlatform(clonedStories,lowerPlatformName);
         }
      }

      filterStoryAndChildByRolePlatform(clonedStories,lowerPlatformName,studioRoleId){
        clonedStories?.forEach(outer => {
            outer.stories = outer?.stories?.filter(stories => stories.all_platforms?.find(e=> e?.name?.toLowerCase() ==  lowerPlatformName) && stories?.role_studio_id == studioRoleId);
            outer?.stories?.forEach(story => {
              if(story?.child_stories?.length > 0){
                  story.child_stories =  story?.child_stories?.filter(child => child.all_platforms?.find(e=> e?.name?.toLowerCase() ==  lowerPlatformName) && child?.role_studio_id == studioRoleId);
              }
             });
          });
      }

      filterStoryAndChildByPlatform(clonedStories,lowerPlatformName){
        clonedStories?.forEach(outer => {
            outer.stories = outer?.stories?.filter(stories => stories.all_platforms?.find(e=> e?.name?.toLowerCase() ==  lowerPlatformName));
            outer?.stories?.forEach(story => {
                if(story?.child_stories?.length > 0){
                    story.child_stories =  story?.child_stories?.filter(child => child.all_platforms?.find(e=> e?.name?.toLowerCase() ==  lowerPlatformName));
                }
            });
        }); 
      }

      flatStories(){
        let rowIndex = 0;
        let mainArray = [];
        let getClonedJourney = this.clonedJourneyStoriesRightPanel;
        let clonedStories = JSON.parse(JSON.stringify(getClonedJourney));
        this.filterStoriesBasedOnMapping(clonedStories);
    
        for (let i = 0; i < clonedStories.length; i++) {
          let featureForMapping = {
            id: clonedStories[i].build_card_feature_id,
            isAddDesignLoaded :true
           }
         let array = this.flatJourneyStories(clonedStories[i],featureForMapping);
          array?.forEach(features => {
            rowIndex++;
            features?.forEach(feature => {
              feature.rowIndex = rowIndex;
            });
          });
         let obj ={ featureTitle : clonedStories[i].title ,features :array}
         if(array && array.length > 0) mainArray.push(obj);
        }
        this.journeyCanvasSub.next(mainArray);
        console.log(mainArray);
      }

}
